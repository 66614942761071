/* eslint-disable no-unused-expressions */
import React from "react";

const IconClock = () => (
  <svg
    width="34"
    height="33"
    viewBox="0 0 34 33"
    className="svg-icon"
  >
    <defs>
      <filter
        id="prefix__a"
        width="140.6%"
        height="153.6%"
        x="-20.3%"
        y="-26.8%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dx="3" dy="3" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feColorMatrix
          in="shadowOffsetOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0.831554878 0 0 0 0 0.882088415 0 0 0 0 1 0 0 0 1 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g
      fill="none"
      fillRule="evenodd"
      filter="url(#prefix__a)"
      transform="translate(0 1)"
    >
      <path
        fill="#FFF"
        stroke="#3E70FC"
        strokeLinecap="round"
        strokeWidth="2"
        d="M6.153 8.357C7.633 6.119 10.916 5 16 5c7.626 0 12 6.126 12 11.828C28 22.53 23.972 28 15.87 28S4 22.53 4 16.828"
      />
      <path fill="#3E70FC" d="M5.072 14L8 19l-8-2.113L5.072 14z" />
      <path fill="#3E70FC" stroke="#3E70FC" d="M15 2H17V4H15z" />
      <rect
        width="8"
        height="2"
        x="12"
        fill="#3E70FC"
        stroke="#3E70FC"
        strokeWidth=".5"
        rx="1"
      />
      <path
        fill="#3E70FC"
        stroke="#3E70FC"
        d="M25.094 6.109H27.094V8.109H25.094z"
        transform="rotate(45 26.094 7.109)"
      />
      <rect
        width="8"
        height="2"
        x="23.508"
        y="4.694"
        fill="#3E70FC"
        stroke="#3E70FC"
        strokeWidth=".5"
        rx="1"
        transform="rotate(45 27.508 5.694)"
      />
      <circle cx="16" cy="17" r="2" fill="#3E70FC" />
      <path
        fill="#3E70FC"
        d="M10.393 11.253c.227-.147.527-.11.714.086l4.572 4.817c.147.155.14.4-.015.547-.017.016-.036.031-.057.044-.227.147-.527.11-.714-.086l-4.572-4.817c-.147-.155-.14-.4.015-.547.017-.016.036-.031.057-.044z"
      />
    </g>
  </svg>
);

export default IconClock;

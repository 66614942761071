/* eslint-disable no-unused-expressions */
import React from "react";

const IconLock = props => (
  <svg 
    {...props}
    viewBox="0 0 29 33"
    className="svg-icon"
  >
    <defs>
      <filter
        id="prefix__a"
        width="162.5%"
        height="153.6%"
        x="-31.2%"
        y="-26.8%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dx="3" dy="3" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feColorMatrix
          in="shadowOffsetOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0.831554878 0 0 0 0 0.882088415 0 0 0 0 1 0 0 0 1 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g
      fill="none"
      fillRule="evenodd"
      stroke="#3E70FC"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      filter="url(#prefix__a)"
      transform="translate(1 1)"
    >
      <rect width="24" height="17" y="11" fill="#FFF" rx="1" />
      <path
        fill="#FFF"
        d="M7 10V5.556C7 2.487 9.462 0 12.5 0S18 2.487 18 5.556V10"
      />
      <path d="M10 0L3.125 7 0 3.818" transform="translate(7 17)" />
    </g>
  </svg>
);

export default IconLock;

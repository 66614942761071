import React, { useState } from "react";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";
import classNames from "classnames";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.scss";
import { ValidationIcon } from "components/Controls";
import InputMask from "react-input-mask";
import constants from "utils/constants";
import { isValidDate, parseDate } from "utils/helpers";

const DateField = ({
  value,
  selected,
  onChange,
  label,
  name,
  onBlur,
  meta: { touched },
  ...props
}) => {
  const [selectedDate, setDate] = useState(selected);

  const toggleWrapperClass = () => {
    const wrapper = document.querySelector(".multistep-form");
    wrapper.classList.toggle("overflowed");
  };

  const handleChange = (data) => {
    setDate(data);
    onChange(data);
  };

  const handleOnBlur = ({ target: { value } }) => {
    if (!parseDate(value)) {
      setDate("");
      onBlur("");
    }
  };

  return (
    <div className="date-field-component flex flex--column">
      <label className="font-uppercased date-field-label">{label}</label>

      <div className="date-field-wrapper">
        <DatePicker
          {...props}
          customInput={
            <InputMask
              type="tel"
              mask="99/99/9999"
              maskChar={null}
              placeholder="MM/DD/YYYY"
            /> 
          }
          onBlur={handleOnBlur}
          selected={selectedDate}
          dateFormat={constants.USER_DATE_FORMAT}
          onCalendarClose={toggleWrapperClass}
          onCalendarOpen={toggleWrapperClass}
          onChange={handleChange}
          className={classNames("date-field-imput", {
            invalid: touched && !selectedDate,
            valid: selectedDate,
          })}
          placeholderText={constants.USER_DATE_FORMAT.toUpperCase()}
        />
        {<ValidationIcon touched={touched} valid={isValidDate(selectedDate)} />}
      </div>
    </div>
  );
};

DateField.propTypes = {
  label: PropTypes.string,
  valid: PropTypes.bool,
};

export default DateField;

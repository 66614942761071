import React, { Component } from "react";
import { TextBtn, CustomCheckbox, BlueBtn } from "components/Controls";
import { LogoView, AgreementLink } from "components/DataDisplay";
import { connect } from "react-redux";
import { selectedOfferSelector, userInfoSelector, loadingSelector, activeStepDataSelector } from '../../selectors';
import { confirmOffer, backToOffers, nextStep } from "../../actions";
import { BuyoutInfo, OfferView } from 'components/DataDisplay';
import { Field, reduxForm } from "redux-form";
import Modal from "@material-ui/core/Modal";
import { IconBtn } from "components/Controls";
import { IconClose } from "components/Svgs";
import FlexshopperContract from "../../ContractStep/FlexshopperContract";
import { formValueSelector } from 'redux-form';
// const selector = formValueSelector('confirmation_step_form');
import { required } from "utils/validations";
import "./styles.scss";
import { finishApplication } from "../../actions";
import { ApplicationAPI } from 'api/application';
import { tokenSelector } from "containers/EmbeddableWidget/selectors";

class GenesisConfirmation2 extends Component {

  state = {
    loading: false
  }

  handleContinueButton = () => {
    this.setState({loading: true})
    const { token, nextStep, data, finishApplication} = this.props
 
    ApplicationAPI.accept_the_offer(
      token,
      data.app_id, 
      {}
    ).then(data => {
      if ( data.errors ) {
        return nextStep({step: 'nobodyApprovedStep'});
      }
      finishApplication();
    }).catch(err => {

    })
  };

  render() {
    const { loading } = this.state;
    const { data } = this.props;

    return (
      <div className="genesis_confirmation_step step5__content flex--column ">
        <div className="flex flex--column"> 

          <h2 className="subtitle font-center">
            Congratulations on your new Account!
          </h2>
          <div className="subtitle2 font-center" >Your new Account, ending in {data.account_last_four} has been created</div>
        </div>

        <LogoView provider={data.provider}/>
        <div className="box">
          <div>Your credit linmit is: <b>${data.approval_amount}</b></div>
          <div>The purchase amount will be charged to your Genesis Credit Account, and your available credit will be <b>${data.approval_amount - data.requested_amount}</b></div>
        </div>
     
        <BlueBtn
          customClass="step5__submit-btn"
          onClick={this.handleContinueButton}
          loading={loading}
        >
          CONFIRM PURCHASE
        </BlueBtn>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  data: activeStepDataSelector()(state),
  token: tokenSelector()(state)
});

const mapDistpatchToProps = dispatch => {
  return {
    confirmOffer: () => dispatch(confirmOffer()),
    finishApplication: values => dispatch(finishApplication(values)),
    nextStep: value => dispatch(nextStep(value))
  };
};

GenesisConfirmation2 = connect(mapStateToProps, mapDistpatchToProps)(GenesisConfirmation2);
GenesisConfirmation2 = reduxForm({
  form: "form",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(GenesisConfirmation2);

export default GenesisConfirmation2;

import React, { Component } from "react";
import { ApplicationRequestAPI } from 'api/application_request';
import { setMetrixSession } from "../../actions";
import { connect } from "react-redux";
import { tokenSelector } from "containers/EmbeddableWidget/selectors";
import { makeCancelable } from "utils/helpers";
import { Helmet } from 'react-helmet';

class TempoeMetrix extends Component {

  state = {
    threatMetrixSessionId: null
  };

  componentDidMount = () => {
    const { token } = this.props;
    this.cancelablePromise = makeCancelable(ApplicationRequestAPI.preinit(token));

    this.cancelablePromise.promise.then(data => {
      this.appendTempoeMetrix(data);
    }).catch((error) =>{
      if (!error.isCanceled) {
        console.error(error);
      }
    })
  }

  componentWillUnmount() {
    this.cancelablePromise.cancel();
  }

  appendTempoeMetrix = (data) => {
    const { setMetrixSession } = this.props;
    if(!data.result) {
      return;
    }
    const tempoeMetrix = data.result.find(item => item.provider === 'tempoe')
    if (!tempoeMetrix){
      return;
    }

    this.setState({
      threatMetrixSessionId: tempoeMetrix.data.threatMetrixSessionId
    })

    setMetrixSession(tempoeMetrix.data.threatMetrixSessionId);
    // const sc = document.createElement("script");
    // sc.setAttribute("src", `https://h.online-metrix.net/fp/tags.js?org_id=52gd48l1&session_id=${tempoeMetrix.data.threatMetrixSessionId}`);
    // sc.setAttribute("type", "text/javascript");
    // document.head.appendChild(sc);

    // const noscript = document.createElement ("noscript")
    // noscript.innerHTML = `<iframe style="width: 100px; height: 100px; border: 0; position: absolute; top: -5000px;" src="https://h.online-metrix.net/fp/tags?org_id=52gd48l1&session_id=${tempoeMetrix.data.threatMetrixSessionId}"></iframe>`
    // document.body.appendChild(noscript);
  }

  render() {
    const { threatMetrixSessionId } = this.state;
    return (<div className="">
       <Helmet>
        <script type="text/javascript" src={`https://h.online-metrix.net/fp/tags.js?org_id=52gd48l1&session_id=${threatMetrixSessionId}`}></script>
      </Helmet>
        <noscript>{
          `<iframe style="width: 100px; height: 100px; border: 0; position: absolute; top: -5000px;" src="https://h.online-metrix.net/fp/tags?org_id=52gd48l1&session_id=${threatMetrixSessionId}"></iframe>`
        }</noscript>
    </div>);
  }
}



const mapStateToProps = (state) => {
  return {
    token: tokenSelector()(state)
  }
};

const mapDistpatchToProps = (dispatch) => {
  return {
    setMetrixSession: (value) => dispatch(setMetrixSession(value)),
  };
};

TempoeMetrix = connect(mapStateToProps, mapDistpatchToProps)(TempoeMetrix);

export default TempoeMetrix;

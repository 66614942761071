import React from "react";
import OfferBlock from '../OfferBlock';
import { buildOfferData } from "containers/MultistepForm/helpers";

const OfferView = ({ offer, preview }) => {
  const data = buildOfferData(offer, preview);
  return (data.map((row, i)=> (
    <OfferBlock
      key={i}
      row={row}
    />
  )));
};

export default OfferView;

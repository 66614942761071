import React from "react";
import classNames from "classnames/bind";
import { CustomTooltip } from "components/DataDisplay";

import "./styles.scss";

const OfferItem = ({ title, value, note, isColumn, tooltip, titleTooltip}) => {


  const renderTitle = () => {
    if (titleTooltip) {
      return (<CustomTooltip 
              title={`${title}:`}
              titleBold={true}
              text={titleTooltip} 
            />)
    } else {
      return (<strong>{title}:</strong>)
    }
  }

  return (
      <div 
        className={classNames(`offer-item`,{
          'offer-item__column': isColumn
        })}>
        <span className="offer-item__title ">
          <span className="pos-relative">
            {renderTitle()}
          </span>
        </span>
        <div className="pos-relative" style={{ marginLeft: '5px' }}>
          <span className="offer-item__accent-text" dangerouslySetInnerHTML={{__html: value}}></span>
          {tooltip && (<CustomTooltip text={tooltip} />)}
          {note && <span className="offer-item__frequency-text">{note}</span>}
        </div>

      </div>
  );
};

export default OfferItem;

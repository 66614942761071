import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Select from "react-select";
import "./styles.scss";

const SelectField = ({ label, input, meta:{touched}, value, options, onChange, customChange, ...props }) => {

  const combineChange = (value) => {
    onChange(value.value);
    customChange(value.value);
  };

  const onBlur = (event, newValue, previousValue) => {
    let blurHandler = props.onBlur;
    if (!newValue) {
      blurHandler(previousValue);
    }
  }

  return (
    <div
      className={classNames("select-field-component flex flex--column", {
        invalid: touched && !value,
        valid:  touched && value,
      })}
    >
      <label className="font-uppercased select-field-label">{label}</label>
      <div  className="select-field-wrapper">
        <Select           
          {...props}
          {...input} 
          onBlur={onBlur}
          options={options} 
          maxMenuHeight={215} 
          placeholder="" 
          onChange={combineChange} />
      </div>
    </div>
  );
};

SelectField.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  valid: PropTypes.bool
};

SelectField.defaultProps = {
  options: [],
  customChange: () => {}
};

export default SelectField;

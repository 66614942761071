import React from "react";
import "./styles.scss";

const BuyoutFootnote = ({ }) => {
  return (
    <div className="payment-note">
      *3-month purchase option in California
    </div>
  );
};

export default BuyoutFootnote;

import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as SharedEventTypes from './eventTypes';
import MultistepForm from "containers/MultistepForm";
import MultistepModal from "containers/MultistepModal";

import './styles.css';
import { tokenSelector } from "containers/EmbeddableWidget/selectors";

class EmbeddableWidget extends Component {
  constructor(props) {
    super(props)
    window.addEventListener("message", this.receiveMessage, false);
  }

  componentDidMount = () => {
    this.appendMetrix();
  }

  appendMetrix = () => {
    if(process.env.REACT_APP_PAYPAIR_ENV === 'production' || process.env.REACT_APP_PAYPAIR_ENV === 'sandbox'){
      var script = document.createElement("script");
      script.innerHTML = '!function(w, d, t, u) {\
        if (w.__ls) return; var f = w.__ls = function() { f.push ? f.push.apply(f, arguments) : f.store.push(arguments)};\
        if (!w.__ls) w.__ls = f; f.store = []; f.v = "1.0";\
        var ls = d.createElement(t); ls.async = true; ls.src = u;\
        var s = d.getElementsByTagName(t)[0]; s.parentNode.insertBefore(ls, s);\
      }(window, document, "script", ("https:" == window.location.protocol ? "https://" : "http://") + "cdn.livesession.io/track.js");\
      __ls("init", "c415fe43.95e6dcc0");\
      __ls("newPageView");';
      document.body.appendChild(script);
    }
  }



  receiveMessage = (event) => {
    if(this.isKnownEvent(event)) {
      this.props.dispatch({ type: event.data.type, payload: event.data.value });
    }
  } 

  isKnownEvent = (event) => {
    return !!event && event.data && event.data.type && event.data.type in SharedEventTypes;
  }


  render() {
    return (
      <MultistepModal />
    );
  }
};

const mapStateToProps = (state) => {
  return {
    token: tokenSelector()(state)
  }
};
const mapDispatchToProps = dispatch => ({
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(EmbeddableWidget);


import { ofType, combineEpics } from 'redux-observable';
import { withLatestFrom, flatMap } from 'rxjs/operators';
import *  as EventTypes from './eventTypes';

import * as modalActions from 'containers/MultistepModal/actions';
import * as formActions from 'containers/MultistepForm/actions';

const initWidget = (action$, state$) => action$.pipe(
  ofType(EventTypes.INIT_WIDGET),
  withLatestFrom(state$),
  flatMap(([action, state]) => {
    if (state.widget.inIframe) {
      window.parent.postMessage({ type: EventTypes.INIT_WIDGET_DONE }, '*');
    }
    return [];
  }),
);

const openWidget = (action$, state$) => action$.pipe(
  ofType(EventTypes.OPEN_WIDGET),
  withLatestFrom(state$),
  flatMap(([action, state]) => {
    return [
      formActions.initUserData(action.payload),
      modalActions.openModal()
    ];
  }),
);

const finishFlow = (action$, state$) => action$.pipe(
  ofType(EventTypes.FINISH_FLOW),
  withLatestFrom(state$),
  flatMap(([action, state]) => {
    if (state.widget.inIframe) {
      window.parent.postMessage({ type: EventTypes.FINISH_FLOW, data: {
        provider: state.leaseForm.selectedOffer.specific_provider_name,
        app_id: state.leaseForm.selectedOffer.app_id,
      }}, '*');
    }
    return [];
  })
);

const cancelFlow = (action$, state$) => action$.pipe(
  ofType(EventTypes.CANCEL_FLOW),
  withLatestFrom(state$),
  flatMap(([action, state]) => {
    if (state.widget.inIframe) {
      window.parent.postMessage({ type: EventTypes.CANCEL_FLOW, data: {}}, '*');
    }
    return [];
  })
);

const sendMessage = (action$, state$) => action$.pipe(
  ofType('SEND_MESSAGE'),
  withLatestFrom(state$),
  flatMap(([action, state]) => {
    if (state.widget.inIframe) {
      window.parent.postMessage({ type: action.payload.type }, '*');
    }
    return [];
  })
);

export default combineEpics(
  initWidget,
  openWidget,
  finishFlow,
  cancelFlow,
  sendMessage
);

import React, { Component } from "react";
import { CustomCheckbox, BlueBtn } from "components/Controls";
import { connect } from "react-redux";
import { selectOffer } from "../../actions";
import { mergeRight } from "ramda";
import { BuyoutInfo, OfferView } from "components/DataDisplay";
import PropTypes from "prop-types";
import { CustomTooltip } from "components/DataDisplay";
import OfferBlock from 'components/DataDisplay/OfferBlock';
import { formValueSelector } from 'redux-form';
const selector = formValueSelector('tempoe_offer_form');
import { required } from "utils/validations";
import { Field, reduxForm } from "redux-form";

import "./styles.scss";

class TempoeOffer extends Component {
  state = {
    loading: false,
    selectedOption: null
  };

  componentDidMount = () => {
    const { offer } = this.props;
    this.setSelected(offer.options[0]);
  };

  setSelected = (option) => {
    const { offer } = this.props;

    option = mergeRight(option, {
      askContractBeforeConfirmation: offer.contractUrl ? false : true,
      app_id: offer.app_id,
      provider: offer.provider,
      payment_schedule: offer.payment_schedule,
      contractUrl: offer.contractUrl,
    });
    this.setState({ selectedOption: option });
  };

  submitForm = (values) => {
    const { selectOffer, offer } = this.props;
    this.setState({loading: true})
    selectOffer({selectedOffer: offer});
  };

  renderInitialPaymentNote = () => {
    const { selectedOption } = this.state;

    if (selectedOption.initial_payment > 50) {
      return <div className="initial_payment_note">Initial payment is a $50 Administrative Fee. If the cost of the leased item(s) 
        exceed(s) your lease approval amount, then the initial payment is the $50 Administrative 
        Fee plus any additional money over your lease approval amount.</div>;
    } 
  }

  renderAgreementCheckbox = ({ input, meta }) => {
    return (
      <CustomCheckbox 
        meta={meta}
        {...input}
      />
    );
  };

  renderLeaseAgreement = () => {
    return (
      <div className="agreemnet-block">
        <div className="flex flex__align--center">
          <Field
            name="isAgreementChecked"
            validate={[required]}
            component={this.renderAgreementCheckbox}
          />
          <div
            className="agreemnet-block__label"
          >
            <span>I acknowledge that I have read and understand the </span>
            <CustomTooltip 
              text={`The lease has a 5-month minimum term ["Initial Term"] and, if
                renewed by the lessee, has a maximum renewal term of 11 months ["Renewal Expiration Date"]. 
                Must be at least 18 years old and income requirements apply. Excludes non-durable goods. 
                No security deposit required. Lease requires consumer to pay an administrative fee at lease signing, 
                a first periodic payment due thereafter, plus 19 weekly, 9 biweekly, or 4 monthly lease payments. 
                After fulfilling the Initial Term you may: (1) continue to lease by making periodic payments 
                in accordance with the terms of the lease agreement ["Option 1"]; (2) exercise a purchase option 
                per the terms of the lease agreement (not available in NJ, VT, WI, or WV) ["Option 2"]; 
                or (3) return the leased items to TEMPOE ["Option 3"]. Should you decide to renew your lease 
                to the Renewal Expiration Date, you must exercise either Option 2 or Option 3 within forty-five (45) 
                business days of the Renewal Expiration Date. Early purchase options (not available in NJ, VT, WI, or WV)
                 are available within 90 days of the lease origination date for cash price, 
                 less amounts paid (excluding fees) and plus applicable taxes."`} 
              title="Lease Disclosures"
            />
            .
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { loading, selectedOption } = this.state;
    const { handleSubmit, isAgreementChecked } = this.props;

    if (!selectedOption) {
      return '';
    } 

    return (
      <form
        onSubmit={handleSubmit(this.submitForm)}
        className="tempoe-offer application-offer"
      >
        <div className="step4__recommended-offer-plan offer-plan__top flex flex--wrap">
          <OfferBlock
            className={'centered type_of_plan_block'}
            row={[{
              title: 'TYPE OF PLAN',
              value: `${selectedOption.type}`
            }]}
          />
          <OfferView offer={selectedOption} />
        </div>
        <BuyoutInfo 
          title={'90-Day Early Payment Option*'}
          text={'This option lets you purchase the leased merchandise within 90-days of lease origination for the cash price, plus the $50 administratve fee.'}
          footnote={'*Not available in NJ, WV, WI, and VT.'}
        />


        {this.renderLeaseAgreement()}
        {this.renderInitialPaymentNote()}
        <BlueBtn
          customClass="step4__recommended-content-btn"
          disabled={!isAgreementChecked}
          loading={loading}
          type="submit"
        >
          CONTINUE
        </BlueBtn>
      </form>
    );
  }
}

TempoeOffer.propTypes = {
  offer: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  isAgreementChecked: selector(state, 'isAgreementChecked'),
});

const mapDistpatchToProps = (dispatch) => {
  return {
    selectOffer: (data) => dispatch(selectOffer(data)),
  };
};

TempoeOffer = connect(mapStateToProps, mapDistpatchToProps)(TempoeOffer);

TempoeOffer = reduxForm({
  form: "tempoe_offer_form",
  initialValues: { isAgreementChecked: false }, 
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(TempoeOffer);

export default TempoeOffer;



import CONSTANTS from 'utils/constants';

const post = (url, method, token, data = {}) => {
  return fetch(`${CONSTANTS.API_URL}/${url}`, {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }, body: JSON.stringify(data)
  }).then(data => data.json())
}

const get = (url, method, token) => {
  return fetch(`${CONSTANTS.API_URL}/${url}`, {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  }).then(data => data.json())
}

export const call = (url, method, token, data = {}) => {
  if(method === 'POST' || method === 'PATCH') {
    return post(url, method, token, data)
  } else {
    return get(url, method, token)
  }
}
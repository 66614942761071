import { DateTime } from "luxon";
import constants from "utils/constants";

export const isValidDate = (d) => {
  return d instanceof Date && !isNaN(d);
};

export const formatDate = (storedDateString) => {
  if (!storedDateString) {
    return "";
  }
  return DateTime.fromISO(storedDateString).toFormat(constants.ISO_DATE_FORMAT);
};

export const parseDate = (userDateString) => {
  if (!userDateString) {
    return "";
  }

  if (typeof userDateString === "string") {
    const formattedDate = DateTime.fromFormat(
      userDateString,
      constants.USER_DATE_FORMAT
    );
    return isValidDate(formattedDate.toJSDate())
      ? formattedDate.toISODate()
      : "";
  } else if (userDateString instanceof Date) {
    return DateTime.fromJSDate(userDateString).toISODate();
  } else {
    return "";
  }
};

export const capitalizeFirstletter = (str) => {
  if (!str) {
    return "";
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const addThousandsSeparator = (x) => {
  if (!x) {
    return "";
  }

  if (x % 1 === 0) {
    x = x.toFixed(0);
  } else {
    x = x.toFixed(2);
  }

  return x.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};


export const makeCancelable = (promise) => {
    let hasCanceled_ = false;

    const wrappedPromise = new Promise((resolve, reject) => {
        promise.then(
            val => hasCanceled_ ? reject({isCanceled: true}) : resolve(val),
            error => hasCanceled_ ? reject({isCanceled: true}) : reject(error)
        );
    });

    return {
        promise: wrappedPromise,
        cancel() {
            hasCanceled_ = true;
        },
    };
};
import React, { Component } from "react";
import PropTypes from "prop-types";
import { BlueBtn, CustomCheckbox } from "components/Controls";
import { Field, reduxForm } from "redux-form";
import { required } from "utils/validations";
import { formValueSelector } from 'redux-form';
const selector = formValueSelector('flexshopper_contract_form');
import { connect } from "react-redux";
import { finishApplication } from "../../actions";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Spinner } from "components/DataDisplay";
import PrintIcon from '@material-ui/icons/Print';

import { Scrollbars } from 'react-custom-scrollbars';

import "./styles.scss";

class FlexshopperContract extends Component {
  state = {
    loading: false,
    submitLoading: false,
    bottomIsReached: false
  }

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  setHeight (e) {
    const iframe = document.getElementById('contract-iframe');
    const container = document.getElementById('contract-iframe-wrapper');

    // set iframe content style to count correct height
    var style = document.createElement('style');
    style.textContent =

      'html, body {\
        height: 100%\
        width: 100%;\
        overflow: hidden;\
        margin: 0;\
      };\
      body > *{\
        padding-right: 10px;\
      }\
      @media (max-width:  640px) {\
        body > table, \
        body > table > tbody, \
        body > table > tbody > tr, \
        body > table > tbody > tr > td \
        body > table > tbody > tr > td > table, \
        body > table > tbody > tr > td > table > tbody, \
        body > table > tbody > tr > td > table > tbody > tr, \
        body > table > tbody > tr > td > table > tbody > tr > td {\
          display: block;\
        }\
        #initial-payment, div.hangtag {\
          transform: scale(0.8);\
          margin-left: -16px;\
        }\
        .payment-schedule {\
          overflow-x: auto;\
        }\
      }'
    ;
    iframe.contentDocument.head.appendChild(style);

    setTimeout(() => {
      const heigth = iframe.contentWindow.document.body.scrollHeight;

      container.style.height = `${heigth}px`;
      iframe.height = `${heigth}px !important`;
    }, 4)

  };

  renderAgreementCheckbox = ({ input, meta }) => {
    return (
      <CustomCheckbox 
        meta={meta}
        {...input}
      />
    );
  };

  submit = (e) => {
    this.setState({submitLoading: true});
    this.props.finishApplication();

  }

  downloadPdf = () => {
    this.setState({loading: true})
    const iframe = document.getElementById('contract-iframe')
    const iframeDocument = (iframe.contentDocument || iframe.contentWindow.document).body;

 
    iframeDocument.style.overflow="visible !important";
    iframeDocument.style.height="auto !important";
    iframeDocument.style.maxHeight="auto !important";

    html2canvas(iframeDocument).then(canvas => {
      try {
        var img = canvas.toDataURL("image/png", 1.0);
        let $w, $actw, $h, $acth, $maxw, $maxh, $count
        $w = $actw = canvas.width;
        $h = $acth = canvas.height;
        var pdf = new jsPDF("p", "mm", "a4");
        var width = $maxw = pdf.internal.pageSize.width;
        var height = $maxh = pdf.internal.pageSize.height;

        if ($w > $maxw) {
            $w = $maxw;
            $h = Math.round($acth / $actw * $maxw);
        }
        pdf.addImage(img, 'JPEG', 0, 0, $w, $h);
        $count = Math.ceil($h / $maxh) - 1; 
        for (var i = 1; i <= $count; i++) {
            let position = - $maxh * i;
            pdf.addPage();
            pdf.addImage(img, 'JPEG', 0, position, $w, $h);
        }
        pdf.save("contract.pdf");
        this.setState({loading: false})
      } catch (e) {
        alert("Error description: " + e.message);
      }
    });
  }

  showSubmit = () => {
    this.setState({bottomIsReached: true});
  }

  hideSubmit = () => {
    this.setState({bottomIsReached: false});
  }

  renderBottomSnippet = () => {
    const { isAgreementChecked } = this.props;
    const { bottomIsReached, submitLoading } = this.state;
    if (bottomIsReached) {
      return (<div >
        <div className="privacy-policy-agreement flex flex__align--center">
          <Field
            name="isAgreementChecked"
            validate={[required]}
            component={this.renderAgreementCheckbox}
          />
          <div className="privacy-policy-agreement__label">
            Check here to "Complete your lease order"
          </div>
         
        </div>
        {isAgreementChecked && <div>
          <div className="aggreement_content">
            BY CHECKING HERE AND CLICKING "COMPLETE YOUR LEASE ORDER", YOU AGREE TO ALL THE TERMS OF THE LEASE, INCLUDING PAYMENT AUTHORIZATION, ARBITRATION, AND CONSENT TO RECEIPT OF ELECTRONIC COMMUNICATIONS. A COPY OF YOUR LEASE WILL BE EMAILED TO YOU UPON SIGNING FOR YOUR RECORDS AND WILL ALSO BE AVAILABLE TO YOU WITHIN YOUR CUSTOMER PORTAL ON OUR WEBSITE (<a href="https://WWW.FLEXSHOPPER.COM" target="_blank">WWW.FLEXSHOPPER.COM</a>).</div>
          <BlueBtn
            onClick={this.submit}
            loading={submitLoading}
            disabled={!isAgreementChecked || submitLoading}
          >
            COMPLETE YOUR LEASE ORDER
          </BlueBtn>
        </div>}
      </div>);
    } else {
      return (<div>
        <div className="privacy-policy-agreement privacy-policy-agreement-note">

          Scroll to Review and Sign
        </div>
      </div>)
    }
  }
  
  handleScroll = (e) => {
    if(e.target.scrollTop >= e.target.scrollHeight - 500){
      this.setState({bottomIsReached: true});
    }
  }


  // calculateIframeHeight = () => {
  //   return document.getElementsByClassName('multistep-form')[0].clientHeight - 80
  // }

  render() {
    const { contract } = this.props;
    const { loading } = this.state;

    const content = window.atob(contract);

    return (
      <div className="flexshopper_contract_step">
        <div onClick={this.downloadPdf} className="download_pdf_btn">
          <span><PrintIcon/></span>
          <span>Download Pdf </span>
          {loading && <Spinner />}
        </div> 


        <Scrollbars 
          onScroll={this.handleScroll}
          style={{ width: '100%' }}
          autoHeight
          autoHeightMin={350} 
        >
          <div id="contract-iframe-wrapper">
            <h2  className="subtitle font-center">Here is your hangtag and lease agreement.</h2>
            <iframe 
              onLoad={e => this.setHeight(e)} 
              srcDoc={content} 
              id="contract-iframe" 
              width="100%"
              frameBorder="0"
              border="0" 
            ></iframe>

          </div>
        </Scrollbars>
        {this.renderBottomSnippet()}
      </div>
    );
  }
}

FlexshopperContract.propTypes = {
  contract: PropTypes.string
};

FlexshopperContract.defaultProps = {
  contract: ""
};

const mapStateToProps = state => ({
  isAgreementChecked: selector(state, 'isAgreementChecked')
});

const mapDistpatchToProps = dispatch => {
  return {
    finishApplication: (data) => dispatch(finishApplication(data))
  };
};

FlexshopperContract = connect(mapStateToProps, mapDistpatchToProps)(FlexshopperContract);
FlexshopperContract = reduxForm({
  form: "flexshopper_contract_form",
  initialValues: { isAgreementChecked: false }, 
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(FlexshopperContract);

export default FlexshopperContract;


import React, { Component } from "react";
import { CustomCheckbox, BlueBtn } from "components/Controls";
import { connect } from "react-redux";
import { selectOffer, nextStep, setSelectedOffer} from "../../actions";
import { mergeRight } from "ramda";
import { BuyoutInfo, OfferView } from "components/DataDisplay";
import PropTypes from "prop-types";
import { CustomTooltip } from "components/DataDisplay";
import OfferBlock from 'components/DataDisplay/OfferBlock';
import { formValueSelector } from 'redux-form';
const selector = formValueSelector('tempoe_offer_form');
import { required } from "utils/validations";
import { Field, reduxForm } from "redux-form";
import { ApplicationAPI } from 'api/application';
import { createStructuredSelector } from "reselect";
import { tokenSelector } from "containers/EmbeddableWidget/selectors";

class GenesisOffer extends Component {
  state = {
    loading: false,
    selectedOption: null
  };

  componentDidMount = () => {
    const { offer } = this.props;
    this.setSelected(offer.options[0]);
  };

  setSelected = (option) => {
    const { offer } = this.props;
    option = mergeRight(option, offer);
    this.setState({ selectedOption: option });
  };

  submitForm = (values) => {
    const { selectedOption } = this.state;
    const { nextStep, setSelectedOffer} = this.props
    this.setState({loading: true})
    setSelectedOffer(selectedOption);

    const nextStepName = selectedOption.account_accepted ? 'genesisConfirmation2' : 'genesisConfirmation1'
    nextStep({step: nextStepName, data: selectedOption});
  };

  render() {
    const { loading, selectedOption } = this.state;
    const { handleSubmit, available } = this.props;

    if (!selectedOption) {
      return '';
    } 

    return (
      <form
        onSubmit={handleSubmit(this.submitForm)}
        className="tempoe-offer application-offer"
      >
        <div className="step4__recommended-offer-plan offer-plan__top flex flex--wrap">
          <OfferBlock
            className={'centered type_of_plan_block'}
            row={[{
              title: 'TYPE OF PLAN',
              value: `${selectedOption.type}`
            }]}
          />
          <OfferView offer={selectedOption} />
        </div>


        <BlueBtn
          customClass="step4__recommended-content-btn"
          loading={loading}
          disabled={!(available)}
          type="submit"
        >
          CONTINUE
        </BlueBtn>
      </form>
    );
  }
}

GenesisOffer.propTypes = {
  offer: PropTypes.object.isRequired,
  available:  PropTypes.bool
};

GenesisOffer.defaultProps = {
  available: true
};

const mapStateToProps = createStructuredSelector({
  token: tokenSelector()
});

const mapDistpatchToProps = (dispatch) => {
  return {
    selectOffer: (data) => dispatch(selectOffer(data)),
    nextStep: value => dispatch(nextStep(value)),
    setSelectedOffer: value => dispatch(setSelectedOffer(value))
  };
};

GenesisOffer = connect(mapStateToProps, mapDistpatchToProps)(GenesisOffer);

GenesisOffer = reduxForm({
  form: "form",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(GenesisOffer);

export default GenesisOffer;



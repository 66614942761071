import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import { ExpandBtn } from "components/Controls";
import { LogoView } from "components/DataDisplay";
import TempoeOffer from "../TempoeOffer";
import PaytomorrowOffer from "../PaytomorrowOffer"; 
import FlexshopperOffer from "../FlexshopperOffer"; 
import GenesisOffer from "../GenesisOffer"; 
import OkinusOffer from "../OkinusOffer"; 
import { addThousandsSeparator } from "utils/helpers";
import "./styles.scss";

class ExpandedOfferBlock extends Component {
  state = {
    isExpanded: false,
    selectedMultiOffer: {
      initial_payment: null,
    },
  };

  toggleExpandView = () => {
    const { isExpanded } = this.state;

    this.setState(() => ({
      isExpanded: !isExpanded,
    }));
  };

  renderOffer = () => {   
    const { offer, available } = this.props;

    switch(offer.provider){
      case 'tempoe': 
        return <TempoeOffer offer={offer} />;
      case 'paytomorrow':
        return <PaytomorrowOffer 
          offer={offer} 
          getSelectedOffer={this.getSelectedOffer}
        />;
      case 'flexshopper':
        return <FlexshopperOffer offer={offer} available={available} />;
      case 'genesis':
        return <GenesisOffer offer={offer} available={available}/>;
      case 'okinus':
        return <OkinusOffer offer={offer} />;
        // return <TempoeOffer offer={offer} />;
      default: 
        return ('');
    }


  };

  getSelectedOffer = data => { 
    this.setState({ selectedMultiOffer: data })
  }

  dueToday = (offer) => {
    const { selectedMultiOffer } = this.state;

    if (offer.provider == 'tempoe' && offer.options[0].initial_payment <= 50) {
      return `$${addThousandsSeparator(offer.options[0].initial_payment)} fee`
    }

    if (offer.provider === 'flexshopper' || offer.provider === 'genesis') {
      return 'No Money Down ($0)';
    }
 
    if (offer.options.length > 1) {
      let payment = addThousandsSeparator(selectedMultiOffer.initial_payment);

      if (selectedMultiOffer.type == "Installment Loan") {
        payment = `${payment} (first payment)`;
      }

      return `$${payment}`; 
    } else {
      return `$${addThousandsSeparator(offer.options[0].initial_payment)}`
    } 
  }

  approvedFor = (offer) => {
    const { selectedMultiOffer } = this.state;
    if (offer.options.length > 1) {
      return selectedMultiOffer.maxLoan;
    } else {
      return offer.approved_amount;
    } 
  }


  renderApprovedFor = () => {
    const { offer, available } = this.props;

    if (offer.authentication_is_required) {
      return ('');
    }

    return (
      <div className="expanded-block-component__offer pos-relative">
        Approved for:{" "}

        <span className={classNames("font-semibold", {
          "font-red": !available
        })}
        >${addThousandsSeparator(this.approvedFor(offer))} 

        </span>
      </div>
    )
  }

  render() {
    const { offer, available } = this.props;
    const { isExpanded } = this.state;

    if (!offer) {
      return "";
    }
    return (
      <div
        className={classNames("expanded-block-component", {
          expanded: isExpanded,
        })}
      >
        {available && (<div className="flex flex__justify--center expanded-block-component__header">
          <span className="font-bold">DUE TODAY:&nbsp;</span>
          <span className="font-semibold font-blue"> {this.dueToday(offer)}</span>
        </div>)}
        <div className="expanded-block-component__content flex flex__justify--between flex__align--center">
          <div className="expanded-block-component__left-bar">
            <LogoView provider={offer.specific_provider_name} />
            {this.renderApprovedFor()}
          </div>
          <div className="expanded-block-component__expand-btn">
            <ExpandBtn 
              onClick={this.toggleExpandView} 
              isExpanded={isExpanded}
             
            >
              VIEW DETAILS
            </ExpandBtn>
          </div>
        </div>
        <div className="expanded-block-component__expanded-content">
          {this.renderOffer()}
        </div>
      </div>
    );
  }
}

ExpandedOfferBlock.propTypes = {
  offer: PropTypes.object.isRequired, 
  available: PropTypes.bool
};

ExpandedOfferBlock.defaultProps = {
  available: true
};

export default ExpandedOfferBlock;

import React, { Component } from "react";
import { CustomCheckbox, BlueBtn } from "components/Controls";
import { connect } from "react-redux";
import { selectOffer } from "../../actions";
import { mergeRight } from "ramda";
import { BuyoutInfo, OfferView } from "components/DataDisplay";
import PropTypes from "prop-types";
import { CustomTooltip } from "components/DataDisplay";
import OfferBlock from 'components/DataDisplay/OfferBlock';
import { formValueSelector } from 'redux-form';
const selector = formValueSelector('tempoe_offer_form');
import { required } from "utils/validations";
import { Field, reduxForm } from "redux-form";


class OkinusOffer extends Component {
  state = {
    loading: false
  };

  componentDidMount = () => {
    const { offer } = this.props;
    this.setSelected(offer.options[0]);
  };

  setSelected = (option) => {
    const { offer } = this.props;

    option = mergeRight(option, {
      askContractBeforeConfirmation: offer.contractUrl ? false : true,
      app_id: offer.app_id,
      provider: offer.provider,
      payment_schedule: offer.payment_schedule,
      contractUrl: offer.contractUrl,
    });
    this.setState({ selectedOption: option });
  };
  
  submitForm = (values) => {
    const { selectOffer, offer } = this.props;
    this.setState({loading: true})
    selectOffer({selectedOffer: offer});
  };

  render() {
    const { loading, selectedOption } = this.state;
    const { handleSubmit } = this.props;

    if (!selectedOption) {
      return '';
    } 

    return (
      <form
        onSubmit={(e) => this.submitForm(e)}
        className="okinus-offer application-offer"
      >
        <div className="step4__recommended-offer-plan offer-plan__top flex flex--wrap">
          <OfferBlock
            className={'centered type_of_plan_block'}
            row={[{
              title: 'TYPE OF PLAN',
              value: `${selectedOption.type}`
            }]}
          />
          <OfferView offer={selectedOption} />
        </div>
      

        <BlueBtn
          customClass="step4__recommended-content-btn"
          disabled={false}
          loading={loading}
          type="submit"
        >
          CONTINUE
        </BlueBtn>
      </form>
    );
  }
}

OkinusOffer.propTypes = {
  offer: PropTypes.object.isRequired,
};

const mapDistpatchToProps = (dispatch) => {
  return {
    selectOffer: (data) => dispatch(selectOffer(data)),
  };
};

OkinusOffer = connect(null, mapDistpatchToProps)(OkinusOffer);

export default OkinusOffer;

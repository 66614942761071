import Step1 from "../Step1";
import Step2 from "../Step2";
import Step3 from "../Step3";
import Step4 from "../Step4";
import ConfirmationStep from "../ConfirmationStep";
import NobodyApprovedStep from "../NobodyApprovedStep";
import ErrorStep from "../ErrorStep";
import AdditionalInfoStep from "../AdditionalInfoStep";
import ContractStep from "../ContractStep";
import SuccessStep from "../SuccessStep";
import AlreadyCompletedStep from "../AlreadyCompletedStep";
import VerificationQuestionsStep from "../VerificationQuestionsStep";

import GenesisConfirmation1 from "../Genesis/ConfirmationStep1";
import GenesisConfirmation2 from "../Genesis/ConfirmationStep2";

export const FORMS = {
  form1: { breadcrumb: null, form: Step1 },
  form2: { breadcrumb: "Application", form: Step2 },
  form3: { breadcrumb: "Application", form: Step3 },
  form4: { breadcrumb: "Decision", form: Step4 },
  confirmationStep: { breadcrumb: "Confirmation", form: ConfirmationStep },
  nobodyApprovedStep: { breadcrumb: "Decision", form: NobodyApprovedStep },
  errorStep: { breadcrumb: "Decision", form: ErrorStep },
  additionalInfoStep: { breadcrumb: "Decision", form: AdditionalInfoStep },
  contractStep: { breadcrumb: "Confirmation", form: ContractStep },
  successStep: { breadcrumb: "Confirmation", form: SuccessStep },
  alreadyCompletedStep: { breadcrumb: "Decision", form: AlreadyCompletedStep },
  verificationQuestionsStep: { breadcrumb: "Decision", form: VerificationQuestionsStep },

  genesisConfirmation1: { breadcrumb: "Confirmation", form: GenesisConfirmation1 },
  genesisConfirmation2: { breadcrumb: "Confirmation", form: GenesisConfirmation2 },
};

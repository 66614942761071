import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ValidationIcon } from "components/Controls";
import { CustomTooltip } from "components/DataDisplay";

import "./styles.scss";


const TextFieldWithTooltip = ({
  label,
  tooltipType,
  tooltipText,
  tooltipTitle,
  onChange, customChange,
  meta: { touched, error, valid },
  input,
  ...props
}) => {
  const combineChange = (e) => {
    const value = e.target.value;
    onChange(value);
    setTimeout(() => customChange(value), 0);
  };

  return (
    <div className="text-field-component flex flex--column">
      <label
        className={classNames(
          "flex flex__align--baseline font-uppercased text-field-label"
        )}
      >
        
          {label}
          <CustomTooltip
            text={tooltipText}
          />
      </label>
      <div className="text-field-wrapper">
        <input
          onChange={combineChange}
          {...props}
          className={classNames("text-field font-bold", {
            invalid: !valid && touched,
            valid: touched && valid,
            disabledPhoneNum: valid && props.disabled,
          })}
          {...input}
          {...props}
        />
  
        {<ValidationIcon
          touched={touched}
          valid={ props.disabled ? null : valid }
      />}
      </div>

      <div
        className={classNames("text-field-error", {
          shown: touched && error !== undefined && error !== 'required'
        })}
      >
        <div className="text-field-error__wrapper">{error}</div>
      </div>
    </div>
  );
}

TextFieldWithTooltip.propTypes = {
  label: PropTypes.string,
  tooltipType: PropTypes.string.isRequired,
  tooltipText: PropTypes.any,
  tooltipTitle: PropTypes.string,
};

TextFieldWithTooltip.defaultProps = {
  tooltipType: "icon",
  tooltipTitle: "",
  customChange: () => {}
};

export default TextFieldWithTooltip;

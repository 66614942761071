import React, { Component } from "react";
import { reduxForm, Field, change, formValueSelector } from "redux-form";
import { BlueBtn } from "components/Controls";
import { connect } from "react-redux";
import { selectOffer } from "../../actions";
import { mergeRight } from "ramda";
import { BuyoutInfo, BuyoutFootnote, OfferView } from "components/DataDisplay";
import { RadioField } from "components/Fields";
import { paymentPeriodSelector } from "../../selectors";
import { createStructuredSelector } from "reselect";
import PropTypes from "prop-types";
import OfferBlock from 'components/DataDisplay/OfferBlock';


class MultiOffer extends Component {
  state = {
    loading: false,
    options: [],
    selectedOption: {
      provider: "",
      type: "",
      offer_id: null,
      payment_period: null,
      payment_period_rate: null,
      first_period: null,
      first_period_rate: null,
      last_period: null,
      last_period_rate: null,
      buyout: false,
      buyout_rate: null,
      initial_payment: null,
    },
  };

  componentDidMount = () => {
    const { offer } = this.props;
    this.setState({
      options: offer.options,
    });
    const defaultSelected = offer.options[offer.options.length - 1];
    this.setSelected(defaultSelected);
    this.props.dispatch(
      change(
        "form",
        "paymentPeriod",
        defaultSelected.payment_period.toString()
      )
    );
  };

  setSelected = (option) => {
    this.props.getSelectedOffer(option);
    const { offer } = this.props;
    option = mergeRight(option, {
      askContractBeforeConfirmation: false,
      app_id: offer.app_id,
      provider: offer.provider,
      specific_provider_name: offer.specific_provider_name,
      payment_schedule: offer.payment_schedule,
    });
    this.setState({ selectedOption: option });
  };

  renderPaymentPeriodField = ({ input, meta }) => {
    return (
      <RadioField
        {...input}
        meta={meta}
        options={this.paymentPeriodOptions()}
        customChange={this.setPaymentPeriod}
      />
    );
  };

  setPaymentPeriod = (value) => {
    const option = this.props.offer.options.find(
      (option) => option.payment_period === parseInt(value)
    );

    this.setSelected(option);
  };

  paymentPeriodOptions = () => {
    const { offer } = this.props;

    return offer.options.map((option) => {
      return {
        value: `${option.payment_period.toString()} Months`,
        key: option.payment_period.toString(),
      };
    });
  };

  submitForm = (e) => {
    const { selectOffer } = this.props;
    const { selectedOption } = this.state;
    e.preventDefault();
    this.setState({loading: true})
    selectOffer({selectedOffer: selectedOption, body: { offer_id: selectedOption.offer_id }});
  };

  valid = () => {
    const { paymentPeriod } = this.props;
    return paymentPeriod;
  };

  renderZeroOfferNote = () => {
    const { selectedOption } = this.state;

    if (selectedOption.apr == 0) {
      return  <BuyoutInfo 
        title={'0% OFFER'}
        text={'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'}
      />
    } 
  }

  renderPaymentPeriodBlock = () => {
    const { offer } = this.props;
    const { selectedOption } = this.state;

    if(offer.options.length === 1) {
      return <OfferBlock
        row={[{
          title: 'PAYMENT PERIOD',
          value: `${selectedOption.payment_period} Months`
        }]}
      />
    } else {
      return (<div className="offer-block offer-block--100">
          <div className="offer-item offer-item__column">
            <strong className="offer-item__title">SELECT YOUR PAYMENT PERIOD:</strong>
            <Field
              name="paymentPeriod"
              component={this.renderPaymentPeriodField}
            />
          </div>
        </div>)
    }
  }

  render() {
    const { selectedOption, loading } = this.state;
    const { available } = this.props;

    return (
      <form
        onSubmit={(e) => this.submitForm(e)}
        className="paytomorrow-offer application-offer"
      >
        <OfferBlock
            className={'centered type_of_plan_block'}
            row={[{
              title: 'TYPE OF PLAN',
              value: `${selectedOption.type}`
            }]}
          />

        {this.renderPaymentPeriodBlock()}

        <div className="step4__recommended-offer-plan offer-plan__top flex flex--wrap">

          <OfferView offer={selectedOption} />
        </div>
        {selectedOption.type == "Lease to Loan" && selectedOption.buyout && <BuyoutInfo 
          title={"Early Buyout Option"}
          text={`Payoff in 90days and only pay a ${selectedOption.buyout_rate}% fee`}
        />}

        {selectedOption.specific_provider_name == 'greenwave' && <BuyoutInfo 
          title={"90-Day Payment Option"}
          text={`Simply pay the cash price within 90 days after the lease is completed.`}
        />}

        {/* {this.renderZeroOfferNote()} */}


        <BlueBtn
          customClass="step4__recommended-content-btn"
          disabled={!available || !this.valid()}
          loading={loading}
          type="submit"
        >
          CONTINUE
        </BlueBtn>
      </form>
    );
  }
}
MultiOffer.propTypes = {
  getSelectedOffer: PropTypes.func,
  offer: PropTypes.object.isRequired,
  available: PropTypes.bool,
};

MultiOffer.defaultProps = {
  available: true,
};

const mapStateToProps = createStructuredSelector({
  paymentPeriod: paymentPeriodSelector()
});

const mapDistpatchToProps = (dispatch) => {
  return {
    selectOffer: (data) => dispatch(selectOffer(data)),
  };
};

MultiOffer = connect(mapStateToProps, mapDistpatchToProps)(MultiOffer);

MultiOffer = reduxForm({
  form: "form",
})(MultiOffer);

export default MultiOffer;

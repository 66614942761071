import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./styles.scss";

const ValidationError = ({ touched, error }) => {
  return (
    <div className={classNames("validation-error", {
        shown: touched && error !== undefined && error !== 'required'  && error !== null
      })}>
      <div className="validation-error__wrapper">
        {error}
      </div>
    </div>
  );
};

ValidationError.propTypes = {
  error: PropTypes.string,
  touched: PropTypes.bool
};

ValidationError.defaultProps = {
  touched: false,
  error: null
};

export default ValidationError;
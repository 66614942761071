import React from "react";
import PropTypes from "prop-types";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import "./styles.scss";

const CustomRadioGroup = ({ onChange, customChange, input, value, options, defaultValue, disabled, ...props }) => {

  const combinedChange = (e) => {
    const value = e.target.value;
    onChange(value);
    setTimeout(() => customChange(value), 0);
  };

  return (
    <div className="custom-radio-group">
      <RadioGroup
        value={value}
        onChange={combinedChange}
      >
        {options.map(item => (
          <FormControlLabel
            key={"item_" + item.key}
            control={
              <Radio
                disabled={disabled}
                disableRipple={true}
                value={item.key}
                name={"item_" +item.key}

              />
            }
            {...input}
            label={item.value}
          />
        ))}
      </RadioGroup>
    </div>
  );
};

CustomRadioGroup.propTypes = {
  customChange: PropTypes.func,
  options: PropTypes.array.isRequired,
};

CustomRadioGroup.defaultProps = {
  customChange: () => {},
  options: [],
};

export default CustomRadioGroup;

import React, { Component } from "react";
import { connect } from "react-redux";
import { selectOffer } from "containers/MultistepForm/actions";
import { mergeRight } from "ramda";
import PropTypes from "prop-types";
import OfferBlock from 'components/DataDisplay/OfferBlock';
import { CustomCheckbox, BlueBtn } from "components/Controls";
import { reduxForm } from "redux-form";
import { ExpandBtn } from "components/Controls";
import { BuyoutInfo, OfferView } from "components/DataDisplay";

import "./styles.scss";

class Offer extends Component {
  state = {
    detailsShown: false,
    loading: false,
    selectedOption: null
  };


  componentDidMount = () => {
    const { offer } = this.props;
    this.setSelected(offer.options[0]);
  };

  setSelected = (option) => {
    const { offer } = this.props;

    option = mergeRight(option, {
      askContractBeforeConfirmation: offer.contractUrl ? false : true,
      app_id: offer.app_id,
      provider: offer.provider,
      payment_schedule: offer.payment_schedule,
      contractUrl: offer.contractUrl,
    });
    this.setState({ selectedOption: option });
  };

  submitForm = (e) => {
    const { selectOffer, offer } = this.props;
    this.setState({loading: true})
    selectOffer({selectedOffer: offer});
  };

  renderAgreementCheckbox = ({ input, meta }) => {
    return (
      <CustomCheckbox 
        meta={meta}
        {...input}
      />
    );
  };

  renderLeaseAgreement = () => {
    return (
      <div className="agreemnet-block">
        <div className="flex flex__align--center">
          <div className="agreemnet-block__label">
            <span>Subject to additional identity verification questions.</span>
          </div>
        </div>
      </div>
    );
  };

  toggleDetails = () => {
    this.setState({detailsShown: !this.state.detailsShown})
  }


  render() {
    const { loading, selectedOption, detailsShown } = this.state;
    const { available } = this.props;

    if (!selectedOption) {
      return '';
    } 

    return (
      <form
        className="flexshopper-offer application-offer"
      >
        <div className="step4__recommended-offer-plan offer-plan__top flex flex--wrap">
          <OfferBlock
            className={'centered type_of_plan_block'}
            row={[{
              title: 'TYPE OF PLAN',
              value: `${selectedOption.type}`
            }]}
          />
          <OfferView offer={selectedOption} />

          <div className="payment-details">
   
            <ExpandBtn 
              customClass="payment-details__toggler"
              onClick={this.toggleDetails} 
              isExpanded={detailsShown}
             
            >
              SEE PAYMENT DETAILS
            </ExpandBtn>
            
            {detailsShown && <div className="payment-details__content">
              <OfferBlock
                row={[{
                  title: 'Cash Price',
                  value: `$${selectedOption.cash_price}`,
                  titleTooltip: "You will own the item(s) and not have to pay any additional amount if you pay the full Cash Price (the Cash Price is the price we charge for a cash sale of the item (or $50 more than the price the merchant you select charges) plus sales taxes and may be higher than the price you would pay if you were to purchase the item(s) directly; if you rent multiple items at the same time, we reduse the Cash Price (and increase the Cost of Rental) by $50 for the second and each subsequent item you rent which makes it less expensive to purchase the items before the end of the term of the Lease). After 90 days, you also have an Early Purchase Option that allow you to own your item(s) for less. Simply refer to the table in your Lease to look up the Early Purchase Option amount after each payment date."
                }]}
              />
              <OfferBlock
                row={[{
                  title: 'Cost Of Rental',
                  value: `$${selectedOption.cost_of_rental}`,
                  titleTooltip: '"Cost of Rental" is the difference between the Total of Payments to Own and the Cash Price of the property/merchandise.'
                }]}
              />
              <OfferBlock
                row={[{
                  title: 'Total Payments To Own',
                  value: `$${selectedOption.total_payments_to_own}`,
                  titleTooltip: '"Total of Payments to Own" is the amount you will pay to own the leased property/merchandise unless you exercise your 90-days Same as Cash purchase option. After 90 days, an early purchase option is still available. See lease for details.'
                }]}
              />
            </div>}
            
          </div>
           
        </div>

        <BuyoutInfo 
          titleTooltip={"You will own the item(s) and not have to pay any additional amount if you pay the full Cash Price (the Cash Price is the price we charge for a cash sale of the item (or $50 more than the price the merchant you select charges) plus sales taxes and may be higher than the price you would pay if you were to purchase the item(s) directly; if you rent multiple items at the same time, we reduce the Cash Price (and increase the Cost of Rental) by $50 for the second and each subsequent item you rent, which makes it less expensive to purchase the items before the end of the term of the Lease). After 90 days, you also have an Early Purchase Option that allows you to own your item(s) for less. Simply refer to the table in your Lease to look up the Early Purchase Option amount after each payment date. "}
          title={"90-Days Same As Cash Option"}
          text={`This option lets you purchase the leased merchandise within 90-days of delivery for the Cash Price.`}
        />

        {this.renderLeaseAgreement()}
        <BlueBtn
          onClick={this.submitForm}
          customClass="step4__recommended-content-btn"
          disabled={!(available)}
          loading={loading}
          type="submit"
        >
          CONTINUE
        </BlueBtn>
      </form>
    );
  }
}


Offer.propTypes = {
  offer: PropTypes.object.isRequired,
  available:  PropTypes.bool
};

Offer.defaultProps = {
  available: true
};

const mapDistpatchToProps = (dispatch) => {
  return {
    selectOffer: (data) => dispatch(selectOffer(data)),
  };
};

Offer = connect(null, mapDistpatchToProps)(Offer);


Offer = reduxForm({
  form: "flexshopper_offer_form",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(Offer);

export default Offer;



import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { TextFieldWithTooltip, DateField } from "components/Fields";
import { BlueBtn, CustomCheckbox } from "components/Controls";
import { STEP2__FEILDS } from "./constants";
import CustomDateField from "components/CustomFields/CustomDateField";
import PaypairTermsofService from 'assets/docs/PaypairTermsofService.pdf';
import PaypairPrivacyPolicy from 'assets/docs/PaypairPrivacyPolicy.pdf';
import PaypairPatriotActDisclosure from 'assets/docs/PaypairPatriotActDisclosure.pdf';
import { formValueSelector } from 'redux-form' 
const selector = formValueSelector('form');

import {
  required,
  isPhoneNumEqual10,
  isNumEqual9,
  greaterOrEqualZeroIncome,
  isNumGreaterThan50000
} from "utils/validations";
import { normalizePhone, normalizeIncome, normalizeSNN } from "utils/normalize";
import "./styles.scss";
import { nextStep } from "../actions";


class Step2 extends Component {
  // state = {
  //   isAgreementChecked: false
  // };

  renderField = ({ input, meta }) => {
    const field = input.name;
    return (
      <TextFieldWithTooltip
        tooltipType={STEP2__FEILDS[field].tooltipType}
        label={STEP2__FEILDS[field].label}
        placeholder={STEP2__FEILDS[field].placeholder}
        meta={meta}
        type="tel"
        {...input}
        tooltipText={STEP2__FEILDS[field].tooltipText}
        tooltipTitle={STEP2__FEILDS[field].tooltipTitle}
        disabled={input.name === "phone" && input.value}
      />
    );
  };



  renderDateField = ({ input, meta }) => {
    const maxDate = this.getMaxDobDate()

    return (
      <DateField
        maxDate={maxDate} 
        showYearDropdown
        showMonthDropdown
        label="DATE OF BIRTH"
        {...input}
        meta={meta}

      />
    );
  };

  getMaxDobDate = () => {
    const date = new Date(); 
    return date.setFullYear( date.getFullYear() - 18 );
  }

  submitForm = (e, valid) => {
    const { nextStep } = this.props;
    e.preventDefault();
    if (valid) {
      nextStep({step: 'form3'});
    }
  };

  // checkedAgreement = () => {
  //   this.setState(({ isAgreementChecked }) => ({
  //     isAgreementChecked: !isAgreementChecked
  //   }));
  // };

  renderForm = () => {
    return (
      <div className="step2__form paypair-form"> 
        <div className="form-item">
        <CustomDateField
          name="dob" 
          validate={[required]}
          component={this.renderDateField} 
        />
        </div>
        <div className="form-item">
          <Field
            name="income"
            validate={[required, greaterOrEqualZeroIncome, isNumGreaterThan50000]}
            component={this.renderField}
            normalize={normalizeIncome}
          />
        </div>
        <div className="form-item form-item--ssn">
          <Field
            name="ssn"
            validate={[required, isNumEqual9]}
            component={this.renderField}
            normalize={normalizeSNN}
          />
        </div>
      </div>
    );
  };

  renderAgreementCheckbox = ({ input, meta }) => {
    return (
      <CustomCheckbox 
        meta={meta}
        {...input}
      />
    );
  };

  renderPrivacyPolicy = () => {
    return (
      <div className="privacy-policy">
        <div className="privacy-policy-text">
          By clicking the “Submit” button I am submitting an application for
          financing (“Application”) and I agree to the
          <a href={PaypairTermsofService} className="link" target="_blank">
            Terms of Use,
          </a>
          <a href={PaypairPrivacyPolicy} className="link" target="_blank">
            Privacy Policy,
          </a>
          and
          <a href={PaypairPatriotActDisclosure} className="link" target="_blank">
            Patriot Act Disclosure,
          </a>
          All of the information I have provided on this 
          Application is true and correct. I agree that I am furnishing my Application 
          to Tire Agent ("Merchant") and I give Merchant permission to share my personal 
          information, including my Application, with its service provider, <span className="font-bold">Paypair</span>, 
          for further sharing with lenders, financiers, or lease providers who may be 
          able to provide financing or lease options to me for goods and services from 
          Merchant or other merchants that implement the <span className="font-bold">Paypair</span> solution either now or 
          in the future, and for marketing products and services to me. I hereby authorize
           Merchant, its service provider, <span className="font-bold">Paypair</span>, and other lenders, financiers, 
           or lease providers with whom my information is shared to verify the 
           information I have provided on this Application and obtain credit 
           bureau reports on me.
        </div>
        <div className="privacy-policy-agreement flex flex__align--center">
          {/* <CustomCheckbox customChange={this.checkedAgreement} /> */}
          <Field
            name="isAgreementChecked"
            validate={[required]}
            component={this.renderAgreementCheckbox}
          />
          <div className="privacy-policy-agreement__label">
            I have read and agree to the disclosure above.
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { valid, isAgreementChecked } = this.props;
    // const { isAgreementChecked } = this.state; 

    const isBtnDisabled =
      !valid ||
      !isAgreementChecked;
    return (
      <form onSubmit={e => this.submitForm(e, valid)} className="step2">
        <h2 className="title2">Please fill in the following information.</h2>
        <div className="step2__content flex flex--column flex__align--center">
          {this.renderForm()}
          {this.renderPrivacyPolicy()}
          <BlueBtn
            type="submit"
            disabled={isBtnDisabled}
            customClass="step2__submit-btn"
          >
            Continue
          </BlueBtn>
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  isAgreementChecked: selector(state, 'isAgreementChecked')
});

const mapDistpatchToProps = dispatch => {
  return {
    nextStep: value => dispatch(nextStep(value))
  };
};

Step2 = connect(mapStateToProps, mapDistpatchToProps)(Step2);

Step2 = reduxForm({
  form: "form",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(Step2);

export default Step2;

import React from "react";
export const ID_TYPE = [
  { label: "Passport", value: "passport" },
  { label: "Driver's License", value: "drivers_license" },
  { label: "State ID Card", value: "state_id" },
  { label: "Military ID", value: "military_id" },
  { label: "Permanent Resident Card", value: "resident_alien_card" },
  { label: "Mexico Matricula Consular Card", value: "matricula_card" }
];


export const ID_STATE = [
  { value: "AL", label: "AL" },
  { value: "AK", label: "AK" },
  { value: "AZ", label: "AZ" },
  { value: "AR", label: "AR" },
  { value: "CA", label: "CA" },
  { value: "CO", label: "CO" },
  { value: "CT", label: "CT" },
  { value: "DE", label: "DE" },
  { value: "FL", label: "FL" },
  { value: "GA", label: "GA" },

  { value: "HI", label: "HI" },
  { value: "ID", label: "ID" },
  { value: "IL", label: "IL" },
  { value: "IN", label: "IN" },
  { value: "IA", label: "IA" },
  { value: "KS", label: "KS" },
  { value: "KY", label: "KY" },
  { value: "LA", label: "LA" },
  { value: "ME", label: "ME" },
  { value: "MD", label: "MD" },

  { value: "MA", label: "MA" },
  { value: "MI", label: "MI" },
  { value: "MN", label: "MN" },
  { value: "MS", label: "MS" },
  { value: "MO", label: "MO" },
  { value: "NE", label: "NE" },
  { value: "NV", label: "NV" },
  { value: "NH", label: "NH" },
  { value: "NJ", label: "NJ" },
  { value: "NM", label: "NM" },

  { value: "NY", label: "NY" },
  { value: "NC", label: "NC" },
  { value: "ND", label: "ND" },
  { value: "OK", label: "OK" },
  { value: "OR", label: "OR" },
  { value: "PA", label: "PA" },
  { value: "RI", label: "RI" },
  { value: "SC", label: "SC" },
  { value: "SD", label: "SD" },
  { value: "TN", label: "TN" },

  { value: "TX", label: "TX" },
  { value: "UT", label: "UT" },
  { value: "VT", label: "VT" },
  { value: "VA", label: "VA" },
  { value: "WA", label: "WA" },
  { value: "WV", label: "WV" },
  { value: "WI", label: "WI" },
  { value: "WY", label: "WY" },
  { value: "DC", label: "DC" },
  { value: "MH", label: "MH" }
];

export const AGREEMENT = [
  { value: "Yes", key: "true" },
  { value: "No", key: "false" }
];

export const PAYOUT_FREQUENCY = [
  { value: "Once per month", key: "monthly" },
  { value: "Every other week", key: "semi_monthly" },
  { value: "Twice a month", key: "bi_weekly" },
  { value: "Weekly", key: "weekly" }
];

export const PAYMENT_OPTIONS_TOOLTIP_TEXT =
  "Some finance companies require additional information from applicants in order to better verify their identity and better tailor their payment plans.";

export const ADDITIONAL_INFO_STEP__FEILDS = {
  additionalInfo: {
    options: AGREEMENT,
    tooltipType: "icon",
    label:
      "WOULD YOU LIKE TO INPUT ADDITIONAL INFORMATION TO APPLY FOR MORE PAYMENT OPTIONS?",
    tooltipText: PAYMENT_OPTIONS_TOOLTIP_TEXT
  },

  routingNum: {
    label: "Checking Routing Number",
    placeholder: "123454321"
  },

  accountNum: {
    label: "Checking Account Number",
    placeholder: "123454321"
  },

  idType: {
    label: "ID TYPE",
    options: ID_TYPE
  },

  idState: {
    label: "ID STATE",
    options: ID_STATE
  },

  idNum: {
    label: "id number",
    placeholder: ""
  }
};


export const PAYOUT_FREQUENCY_TOOLTIP_TEXT = (
  <div className="frequency-payment-tooltip">
    <div style={{ margin: 0 }}>
      <span className="font-semibold font-italic"> Once per month:</span>
      Jan 1st, Feb 1st, etc
    </div>

    <div>
      <span className="font-semibold font-italic"> Every other week:</span>
      Jan 3rd, Jan 17th, Jan 31st, etc
    </div>

    <div>
      <span className="font-semibold font-italic"> Twice a month:</span>
      Jan 15th, Jan 31st, etc
    </div>

    <div>
      <span className="font-semibold font-italic">Weekly:</span>
      Jan 1st, Jun 8th, Jun 15th, etc
    </div>
  </div>
);
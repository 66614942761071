import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import './styles.scss';
import classNames from "classnames";

const Spinner = props => {

  const customClass = props.className
  return (
    <div className={classNames("app-spinner-wrapper flex flex__justify--center", customClass )}>
      <CircularProgress size={"100%"}/>
    </div>
  );
};

export default Spinner;
import React, { Component } from "react";
import { connect } from "react-redux";
import { finishApplication, cancelApplication } from "../../actions";
import PropTypes from "prop-types";
import Frame from "../Frame";
import CONSTANTS from 'utils/constants';
import { closeModal } from "containers/MultistepModal/actions";

class PaytomorrowContract extends Component {

  receiveMessage = (event) => {
    const { finishApplication, cancelApplication, closeModal } = this.props
    if (!event.origin.match(/paytomorrow/))
      return;
    switch (event.data) {
      //To get next message we need to have &cancellable=false in contractUrl',
      case "pt-close":
        // cancelApplication();
        closeModal();
        break;
      case "pt-confirmation":
        finishApplication();
        break;
      case "pt-finished":
        closeModal();
        break;
    }
  } 

  render() {
    const { contractUrl } = this.props
    const url = contractUrl ? `${contractUrl}&close_button=false` : '';
    return ( 
      <Frame 
        contractUrl={url}
        onReceiveMessage={this.receiveMessage}
      />
    )
  }
}

PaytomorrowContract.propTypes = {
  contractUrl: PropTypes.string
};

PaytomorrowContract.defaultProps = {
  contractUrl: ""
};

const mapDistpatchToProps = dispatch => {
  return {
    finishApplication: values => dispatch(finishApplication(values)),
    cancelApplication: values => dispatch(cancelApplication(values)),
    closeModal: () => dispatch(closeModal())
  };
};

PaytomorrowContract = connect(null, mapDistpatchToProps)(PaytomorrowContract);

export default PaytomorrowContract;


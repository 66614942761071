import React, { Component } from "react";
import {
  activeStepDataSelector
} from "../selectors";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";

class ErrorStep extends Component {
  render() { 
    const {activeStepData} = this.props;
    return (<p className="text-center">{activeStepData.message || ''}</p>);
  }
}

const mapStateToProps = createStructuredSelector({
  activeStepData: activeStepDataSelector(),
});

ErrorStep = connect(mapStateToProps)(ErrorStep);
export default ErrorStep;

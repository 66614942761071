import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import {
  RadioFieldWithTooltip,
  RadioField,
  DateField,
} from "components/Fields";
import { BlueBtn } from "components/Controls";
import { onlyNumbers } from "utils/normalize";

import {
  PAYOUT_FREQUENCY_TOOLTIP_TEXT,
  ADDITIONAL_INFO_STEP__FEILDS,
  PAYOUT_FREQUENCY,
  AGREEMENT,
} from "../constants/collections";
import { required } from "utils/validations";

import "./styles.scss";
import { initApplication, continueInitApplication } from "../actions";
import CustomDateField from "components/CustomFields/CustomDateField";
import { loadingSelector, formValuesSelector, metrixSessionIdSelector } from '../selectors';
import AdditionalInfoBlock from "components/DataDisplay/AdditionalInfoBlock"; 
import { formValueSelector } from 'redux-form' 
const selector = formValueSelector('form')


class Step3 extends Component {
  state = {
    additionalData: {},
    additionalDataValid: false
  };

  renderPayFrequencyField = ({ input, meta }) => {
    return (
      <RadioFieldWithTooltip
        meta={meta}
        {...input}
        options={PAYOUT_FREQUENCY}
        tooltipType="icon"
        label="HOW OFTEN DO YOU GET PAID?"
        tooltipText={PAYOUT_FREQUENCY_TOOLTIP_TEXT}
      />
    );
  };

  renderLastPayckeckField = ({ input, meta }) => {
    const interval = this.getLastPayckeckDateInt();

    return (
      <DateField
        label="LAST PAYCHECK DATE"
        {...input}
        meta={meta}
        minDate={interval.min}
        maxDate={interval.max}
      />
    );
  };

  getLastPayckeckDateInt = () => {
    const date = new Date();
    return {
      min: (new Date()).setDate(date.getDate() - 30),
      max: (new Date()).setDate(date.getDate())
    }
  };

  getNextPayckeckDateInt = () => {
    const date = new Date();
    return {
      min: (new Date()).setDate(date.getDate() + 1),
      max: (new Date()).setDate(date.getDate() + 31)
    }
  };

 

  renderNextPayckeckField = ({ input, meta }) => {
    const interval = this.getNextPayckeckDateInt();
    return (
      <DateField
        label="NEXT PAYCHECK DATE"
        {...input}
        meta={meta}
        minDate={interval.min}
        maxDate={interval.max}
      />
    );
  };

  renderDirectDepositField = ({ input, meta }) => {
    return (
      <RadioField
        {...input}
        meta={meta}
        options={AGREEMENT}
        label="DO YOU GET PAID BY DIRECT DEPOSIT?"
      />
    );
  };

  renderHasAdditionalInfoField = ({ input, meta }) => {
    // const { isUserReturned } = this.props
    return (
      <RadioFieldWithTooltip
        {...input}
        // disabled={isUserReturned ? true : false }
        meta={meta}
        options={AGREEMENT}
        tooltipType="icon"
        label="Would you like to add more details to apply for a <span class='underlined'>No Money Down</span> option?"
        tooltipText={ADDITIONAL_INFO_STEP__FEILDS["additionalInfo"].tooltipText}
      />
    );
  };



  renderForm = () => {
    return (
      <div className="step3__form paypair-form paypair-form-padding">
        <div className="form-item form-item--frequency-payment">
          <Field 
            name="payFrequency" 
            validate={required}
            component={this.renderPayFrequencyField} 
          />
        </div>
        <div className="form-item-group">
          <div className="form-item form-item--date">
            <CustomDateField
              name="lastPaycheck"
              validate={[required]}
              component={this.renderLastPayckeckField}
            />
          </div>

          <div className="form-item  form-item--date">
            <CustomDateField
             name="nextPaycheck"
              validate={[required]}
              component={this.renderNextPayckeckField}
            />
          </div>
        </div>

        <div className="form-item">
          <Field
            validate={[required]}
            name="directDeposit"
            component={this.renderDirectDepositField}
          />
        </div>
        <div className="form-item form-item--payment-options">
          <Field
            
            name="hasAdditionalInfo"
            validate={[required]}
            component={this.renderHasAdditionalInfoField}

          />
        </div>
      </div>
    );
  };

  submitForm = (values) => {
    // if( isUserReturned ) {
    //   this.props.continueInitApplication({values: this.buildBodyToInitApplication()});
    // } else {
      this.props.initApplication({ values: this.buildBodyToInitApplication() });
    // }
    // }
  };

  buildBodyToInitApplication = () => {
    const { values, metrixSessionId } = this.props;
    const { additionalData } = this.state;
    const applicationRequest = {
      metrixSessionId: metrixSessionId,
      personalInfo: {
        phone: values.phone && onlyNumbers(values.phone),
        dob: values.dob,
        ssn: values.ssn && onlyNumbers(values.ssn),
      },
      employmentInformation: {
        payFrequency: values.payFrequency,
        monthlyIncome: values.income && parseFloat(onlyNumbers(values.income)),
        last_payday_on: values.lastPaycheck,
        next_payday_on: values.nextPaycheck,
        direct_deposit:
          values.directDeposit && JSON.parse(values.directDeposit),
      },
    };

    if (values.hasAdditionalInfo === "true") {
      applicationRequest.has_additional_info = true;
      applicationRequest.additional_info_data = {};
      if (additionalData.routingNum && additionalData.accountNum ) {
        applicationRequest.additional_info_data.bankAccount = {
          routing: additionalData.routingNum,
          account: additionalData.accountNum,
          type: 'checking'
        };
      }
    }
    return applicationRequest;
  };

  setAdditionalData = (data) => {
    this.setState({
      additionalData: data.data,
      additionalDataValid: data.valid
    });
  }

  render() {
    const { valid, loading, handleSubmit, hasAdditionalInfo } = this.props;
    const { additionalDataValid } = this.state;

    const fullFormValid = hasAdditionalInfo === 'true' ? valid  && additionalDataValid : valid

    return (
      <form onSubmit={handleSubmit(this.submitForm)} className="step3">
        <span className="subtitle font-center">One More Step</span>
        <h2 className="title2">
          We want to make sure your payments work for you. Please fill in the
          information below.
        </h2>
        <div className="step3__content flex flex--column flex__justify--center">
          {this.renderForm()}

          {hasAdditionalInfo === 'true' && 
            <AdditionalInfoBlock
              onDataChange={this.setAdditionalData}
            />}
          <div className=" paypair-form-padding">
            <BlueBtn
              customClass="step3__submit-btn"
              disabled={!fullFormValid}
              loading={loading}
              type="submit"
            >
              Submit
            </BlueBtn>
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  values: formValuesSelector()(state),
  loading: loadingSelector()(state),
  metrixSessionId: metrixSessionIdSelector()(state),
  hasAdditionalInfo: selector(state, 'hasAdditionalInfo')
});

const mapDistpatchToProps = (dispatch) => {
  return {
    initApplication: (values) => dispatch(initApplication(values)),
    continueInitApplication: values => dispatch(continueInitApplication(values))
  };
};

Step3 = connect(mapStateToProps, mapDistpatchToProps)(Step3);

Step3 = reduxForm({
  form: "form",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(Step3);

export default Step3;
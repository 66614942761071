
import { FORMS } from './forms';
import { 
  ID_TYPE, 
  ID_STATE, 
  AGREEMENT, 
  PAYOUT_FREQUENCY, 
  ADDITIONAL_INFO_STEP__FEILDS
} from './collections';

const breadcrumbMap = [
  { key: "Application", label: "Application" },
  { key: "Decision", label: "Decision" },
  { key: "Confirmation", label: "Confirmation" }
];

export { 
  FORMS,
  breadcrumbMap,
  ID_TYPE, 
  ID_STATE, 
  AGREEMENT, 
  PAYOUT_FREQUENCY, 
  ADDITIONAL_INFO_STEP__FEILDS
};



import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { Provider } from "react-redux";
import { createStore, combineReducers, applyMiddleware} from "redux";
import { reducer as reduxFormReducer } from "redux-form";
import modalReducer from "./containers/MultistepModal/reduser";
import leaseFormReducer from "./containers/MultistepForm/reduser";
import formReducer from "./containers/MultistepForm/formReduser";
import widgetReduser from "./containers/EmbeddableWidget/reducer";

import { composeWithDevTools } from 'redux-devtools-extension';
import { createEpicMiddleware, combineEpics } from 'redux-observable';
import widgetEpic from './containers/EmbeddableWidget/epics';
import formEpic from './containers/MultistepForm/epics';
import modalEpic from './containers/MultistepModal/epics';

import "./index.css";
import './styles.scss';


import MultistepForm from "./containers/MultistepForm";
import MultistepModal from './containers/MultistepModal';
import EmbeddableWidget from './containers/EmbeddableWidget';

import * as serviceWorker from "./serviceWorker";

const reducers = combineReducers({
  form: reduxFormReducer.plugin(formReducer),
  leaseForm: leaseFormReducer,
  modal: modalReducer,
  widget: widgetReduser
});

const compose = composeWithDevTools({ trace: true, traceLimit: 25 });

const epicMiddleware = createEpicMiddleware();

let store = createStore(reducers, compose(
  applyMiddleware(epicMiddleware)
));

epicMiddleware.run(
  combineEpics(
    widgetEpic,
    formEpic,
    modalEpic
  )
)

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Route exact path="/modal" component={MultistepModal} />
      <Route path="/form" component={MultistepForm} />
      <Route path="/widget" component={EmbeddableWidget} />
    </Router>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

export const appRequest = {"metrixSessionId":"340e8635-77dc-4deb-bb73-389d45e24532+102959","personalInfo":{"email":"valusha1591130188348@flexshopper.com","firstName":"Valbpuimib","lastName":"Grinsuftbq","phone":"2223444334","dob":"2002-06-02","ssn":"323333332"},"residenceInfo":{"street":"1224 Lala Lane","country":"US","city":"Cincinnati","state":"OH","zip":"45239"},"orderId":"123","shipping":0,"items":[{"description":"Rose Gold iPhone Xs 256GB","quantity":1,"price":1000,"category":"123","brand":"Apple","model":"iPhone Xs Rose Gold","sku":"1111","taxClass":"123","taxAmount":10}],"has_additional_info":true,"additional_info_data":{"bankAccount":{"routing":"123123123","account":"123123","type":"checking"}},"employmentInformation":{"payFrequency":"weekly","monthlyIncome":5000,"last_payday_on":"2020-06-01","next_payday_on":"2020-06-08","direct_deposit":false}}


export const paytomorrowOffer = {
  provider: 'paytomorrow',
  specific_provider_name: 'paytomorrow',
  app_id: '123',
  lease_app_id: '123',
  approved_amount: 1050,
  payment_schedule: 'monthly',
  approve_status: 'approved',
  available: true,
  options: [{
    type: "Installment Loan",
    offer_id: 21,
    payment_period: 12,     
    payment_period_rate: 103, 
    buyout: true,
    buyout_rate: 23,
    initial_payment: 78.40,
    apr: 0,
    maxLoan: 5000
  }
  ,{
    type: "Installment Loan",
    offer_id: 21,
    payment_period: 24,     
    payment_period_rate: 105, 
    first_period: 3,
    first_period_rate: 98,
    last_period: 9,
    last_period_rate: 100,
    buyout: true,
    buyout_rate: 11,
    initial_payment: 34,
    maxLoan: 5000,
    
  },{
    type: "Lease to Loan",
    offer_id: 21,
    payment_period: 28,     
    payment_period_rate: 105, 
    first_period: 3,
    first_period_rate: 98,
    last_period: 9,
    last_period_rate: 100,
    buyout: true,
    buyout_rate: 11,
    initial_payment: 34,
    maxLoan: 5000
  }]
};
export const greenwaveOffer = {
  provider: 'paytomorrow',
  specific_provider_name: 'greenwave',
  app_id: '123',
  lease_app_id: '123',
  approved_amount: 1050,
  payment_schedule: 'monthly',
  approve_status: 'approved',
  available: true,
  options: [{
    type: "NCN",
    offer_id: 21,
    payment_period: 12,     
    payment_period_rate: 103, 
    buyout: true,
    buyout_rate: 23,
    initial_payment: 78.40,
    apr: 0,
    maxLoan: 5000
  }]
}

export const flexshopperOffer = {
  provider: 'flexshopper',
  specific_provider_name: 'flexshopper',
  app_id: '10feefe7-d5b3-40d2-9c18-5c1f5f724e34',
  lease_app_id: '123',
  approved_amount: 1200,
  approve_status: 'approved',
  payment_schedule: 'weekly',
  available: true,
  // approve_status: 'need_additionals',
  // authentication_is_required: false,
  verification_is_required: true,
  options: [{
    // type: "Lease to Own",
    // offer_id: null,
    // payment_period: 12,
    // payment_period_rate: 103, 
    // buyout: false,
    // initial_payment: 78
    applicationId: "33feea5c-98dd-11ea-86d4-b2ba3d7c7627",
    approved: true,
    availableBalance: 1432,
    availableWeeklySpending: 58.66,
    buyout: false,
    buyout_rate: null,
    createdAt: "2020-05-18T07:57:21.000Z",
    initial_payment: 0,
    offer_id: null,
    payment_period: 12,
    payment_period_rate: "46.15",
    spendingLimit: 1432,
    status: "approved",
    type: "Lease to Own",
    cash_price: 1050,
    cost_of_rental: 950,
    total_payments_to_own:  2000
  }]
};

export const tempoeOffer = {
  provider: 'tempoe',
  specific_provider_name: 'tempoe',
  app_id: '123',
  available: true,
  lease_app_id: '123',
  approved_amount: 1200,
  payment_schedule: 'weekly',
  approve_status: 'approved',
  options: [{
    type: "Lease",
    payment_period_min: 5,
    payment_period_max: 11,
    offer_id: null,
    payment_period: 12,
    payment_period_rate: 103, 
    buyout: false,
    initial_payment: 67
  }]
};

export const tempoe2Offer = {
  provider: 'tempoe',
  specific_provider_name: 'tempoe',
  app_id: '123',
  lease_app_id: '123',
  approved_amount: 700,
  payment_schedule: 'weekly',
  approve_status: 'approved',
  options: [{
    type: "Lease",
    payment_period_min: 5,
    payment_period_max: 11,
    offer_id: null,
    payment_period: 12,
    payment_period_rate: 103, 
    buyout: false,
    initial_payment: 0
  }]
};

export const okinusOffer = {
  provider: 'okinus',
  specific_provider_name: 'okinus',
  app_id: '123',
  available: true,
  lease_app_id: '123',
  approved_amount: 2000,
  payment_schedule: 'monthly',
  approve_status: 'approved',
  options: [{
    type: "Lease To Own",
    offer_id: null,
    payment_period: 20,
    payment_period_rate: 103, 
    buyout: false,
    initial_payment: 103
  }]
};

export const genesisOffer = {
  provider: 'genesis',
  specific_provider_name: 'genesis',
  app_id: '123',
  available: true,
  lease_app_id: '123',
  approved_amount: 2000,
  payment_schedule: 'monthly',
  approve_status: 'approved',
  options: [{
    type: "Credit Line",
    offer_id: null,
    payment_period: 20,
    payment_period_rate: 103, 
    buyout: false,
    initial_payment: 0
  }]
};

export const applicationResponseStub = {
  request_id: null,
  amount: 1000,
  updated: false,
  additionals_provided: false,
  offers: [ genesisOffer, paytomorrowOffer, greenwaveOffer, flexshopperOffer, tempoeOffer, tempoe2Offer]
}

export const defaultSelectedOffer = {
  provider: 'paytomorrow',
  specific_provider_name: 'paytomorrow',
  payment_schedule: 'monthly', 
  app_id: 12,
  offer_id: 21,
  payment_period: 24,     
  payment_period_rate: 105, 
  first_period: 12,
  first_period_rate: 100,
  last_period: 12,
  last_period_rate: 102,
  buyout: true,
  buyout_rate: 11,
  initial_payment: 34
};

export const defaultAdditionalInfoData = {
  bankAccount: {
    routing: null,
    account: null,
    type: null
  }, 
  id_document: {
    type: null,
    state: null,
    number: null,
    expiration: null
  }
};

const baseResidence = {
  country: 'US',
  street: "1224 Lala Lane",
  city: "Cincinnati",
  state: "OH",
  zip: "45239",
}
const okinusResidence = {
  city: "Hartford",
  zip: "06106",
  street: "502 Broad St",
  state: "CT",
  country: "US"
}

export const userDefaultData = {
    orderId: '123',
    shipping: 0,
    items: [{
      description: 'Rose Gold iPhone Xs 256GB', 
      quantity: 1, 
      price: 1000,
      category: '123',
      brand: 'Apple', 
      model: 'iPhone Xs Rose Gold', 
      sku: '1111',
      taxClass: '123',
      taxAmount: 10
    }],
    personalInfo: {
      email: `valusha${(new Date()).getTime()}@flexshopper.com`,
      firstName: `Val${Math.random().toString(36).replace(/[^a-z]+/g, '')}`,
      lastName: `Grin${Math.random().toString(36).replace(/[^a-z]+/g, '')}`
    },
    residenceInfo: okinusResidence
};



export const genesisSelectedOffer = {
  offer_id: 0,
  app_id: 12,
  payment_period_rate: 103, 
  type:  "Credit Line",
  provider: 'genesis',
  specific_provider_name: 'genesis'
};

export const paytomorrowSelectedOffer = {
  offer_id: 21,
  app_id: 12,
  payment_period: 12,     
  payment_period_rate: 103, 
  first_period: 3,
  first_period_rate: 98,
  last_period: 9,
  last_period_rate: 100,
  buyout: true,
  buyout_rate: 23,
  type:  "Lease to Loan",
  initial_payment: 78,
  contractUrl: 'https://ecom-staging.paytomorrow.com/esign/leaseloan?app=72d82460-8551-4d9d-95fd-e1e67648f364&auth=c974930e-2c7b-4fe4-857c-ac8b31048b00&back_button=false&cancellable=false',
  contract: null,
  provider: 'paytomorrow',
  specific_provider_name: 'paytomorrow'
}

export const greenWaveSelectedOffer = {
  offer_id: 21,
  app_id: 12,
  payment_period: 12,     
  payment_period_rate: 103, 
  first_period: 3,
  first_period_rate: 98,
  last_period: 9,
  last_period_rate: 100,
  buyout: true,
  buyout_rate: 23,
  type:  "Lease to Loan",
  initial_payment: 78,
  contractUrl: 'https://ecom-staging.paytomorrow.com/esign/leaseloan?app=72d82460-8551-4d9d-95fd-e1e67648f364&auth=c974930e-2c7b-4fe4-857c-ac8b31048b00&back_button=false&cancellable=false',
  contract: null,
  provider: 'paytomorrow',
  specific_provider_name: 'greenwave'
}


export const flexshopperSelectedOffer = {
  provider: 'flexshopper',
  specific_provider_name: 'flexshopper',
  payment_schedule: 'weekly', 
  app_id: 12,
  offer_id: 21,
  payment_period: 12,     
  payment_period_rate: 105, 
  buyout: false,
  initial_payment: 34,
  contract: "PGgxPllvdSBjYW4gZWRpdCB0aGlzIGRlbW8gdGV4dCE8L2gxPgo8aDI+SG93IHRvIHVzZSB0aGUgZWRpdG9yOjwvaDI+CjxwPlBhc3RlIHlvdXIgZG9jdW1lbnRzIGluIHRoZSB2aXN1YWwgZWRpdG9yIG9uIHRoZSBsZWZ0IG9yIHlvdXIgSFRNTCBjb2RlIGluIHRoZSBzb3VyY2UgZWRpdG9yIGluIHRoZSByaWdodC4gPGJyIC8+RWRpdCBhbnkgb2YgdGhlIHR3byBhcmVhcyBhbmQgc2VlIHRoZSBvdGhlciBjaGFuZ2luZyBpbiByZWFsIHRpbWUuJm5ic3A7PC9wPgo8cD5DbGljayB0aGUgQ2xlYW4gYnV0dG9uIHRvIGNsZWFuIHlvdXIgc291cmNlIGNvZGUuPC9wPgo8aDI+U29tZSB1c2VmdWwgZmVhdHVyZXM6PC9oMj4=",
  data: {
    "id": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJxdWVzdGlvblNldElkIjoiMTk1NzY0MzE4IiwidHJhbnNhY3Rpb25JZCI6IjMxMDAwMzc2MzM5Nzg4IiwiaWF0IjoxNTg5ODgzMzk3fQ.2p9SVnBXNzxbm_Key0jA53xM7SAp56wozcJjXqNtoZY",
            "questions": [
                {
                    "id": "2513264468",
                    "label": "Which of the following corporations have you ever been associated with?",
                    "choices": [
                        {
                            "id": "3475349038",
                            "label": "Al State Insurance"
                        },
                        {
                            "id": "3475349048",
                            "label": "E-tron Technologies"
                        },
                        {
                            "id": "3475349058",
                            "label": "Future Looks Of Triad"
                        },
                        {
                            "id": "3475349068",
                            "label": "Pri Super Blue Technology"
                        },
                        {
                            "id": "3475349078",
                            "label": "Travelers Insurnace"
                        },
                        {
                            "id": "3475349088",
                            "label": "None of the above"
                        }
                    ]
                }
            ]
  }
}

export const tempoeSelectedOffer = {
  provider: 'tempoe',
  specific_provider_name: 'tempoe',
  payment_schedule: 'monthly', 
  app_id: 12,
  offer_id: null,
  payment_period: 12,     
  payment_period_rate: 105, 
  buyout: false,
  initial_payment: 34,
  contractUrl: "https://t7.testtempoe.com/Compliance/DocumentRenderer2/home/GetDocuments?approvalId=TC4VBJ3&communicationPreferenceId=2&showPasscode=False&showSignaturebox=True&channel=14&InlinePayments=True&ConversationID=2c7a642c-db98-4ab3-adca-53c034bfe341&StoreID=102959&cf=Email"
}


import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { TextFieldWithTooltip } from "components/Fields";
import {
  IconClock,
  IconLock,
  IconCards,
} from "components/Svgs";
import { CustomTooltip } from "components/DataDisplay";
import { CustomCheckbox, BlueBtn } from "components/Controls";
import {
  SCORE_TOOLTIP_TEXT,
  PHONE_FIELD_TOOLTIP_TEXT,
} from "./constants";
import { required, isPhoneNumEqual10, isCodeEqual6 } from "utils/validations";
import { normalizePhone, onlyNumbers } from "utils/normalize";
import { connect } from "react-redux";
import { tokenSelector } from "containers/EmbeddableWidget/selectors";
import { subscribingDataSelector } from "containers/MultistepForm/selectors";

import VerificationCode from "components/CustomFields/VerificationCode"; 
import { UsersAPI } from 'api/users';
import { processAuthUserResponse } from "../actions";
import { SubmissionError } from 'redux-form'
import TempoeMetrix from './TempoeMetrix'
import { mergeRight } from "ramda";
import { formValueSelector } from 'redux-form';
const selector = formValueSelector('form');
import "./styles.scss";

class Step1 extends Component {
  state = {
    loading: false,
    showPasscodeField: false,
    passcodeError: false,
    phoneError: false
  };

  initUser = (params) => {
    const { token } = this.props;
    return UsersAPI.send_code(token, params).then(data => {
      this.setState({loading: false});
      if (data.status !== 'ok') {
        // throw new SubmissionError({
        //   phone: data.message
        // })
        this.setState({
          phoneError: data.message
        });
        return
      }

      this.showPasscodeField();
      if(process.env.REACT_APP_PAYPAIR_ENV === 'production' || process.env.REACT_APP_PAYPAIR_ENV === 'sandbox'){
        var script = document.createElement("script");
        script.innerHTML = '__ls("identify", {params:{phone: ' + params.phone + '}});';
        document.body.appendChild(script);
      }
    })
  }

  authUser = (params) => {
    const { token, processAuthUserResponse, isAgreed} = this.props;
    return UsersAPI.auth(token, params).then(data => {
      if (data.status !== 'ok') {
        this.setState({
          loading: false,
          passcodeError: this.formatOtpError(data.error)
        });
        return
        // throw new SubmissionError({
        //   passcode: this.formatOtpError(data.error)
        // })
      }

      processAuthUserResponse({request: params, response: data});
    })
  }

  showPasscodeField = () => {
    this.setState({ showPasscodeField: true });
  }
  
  renderPhoneField = ({ input, meta }) => {
    return (
      <TextFieldWithTooltip
        tooltipType="icon"
        label="mobile phone number"
        placeholder="(123) 456-7890"
        meta={meta}
        type="tel"
        {...input}
        tooltipText={PHONE_FIELD_TOOLTIP_TEXT}
      />
    );
  };
  
  renderCodeField = ({ input, meta }) => {
    const { showPasscodeField } = this.state;
    return (
      <VerificationCode 
        resend={false}
        meta={meta}
        {...input}
        trigger={showPasscodeField}
        text="Please enter the 6-digit verification code sent to your mobile phone."
      />
    );
  };

  submitForm = (values) => {
    const { showPasscodeField } = this.state;
    this.setState({loading: true});

    if (showPasscodeField) {
      return this.authUser({
        phone:  values.phone && onlyNumbers(values.phone),
        otp_code: values.passcode && onlyNumbers(values.passcode)
      });
    }

    return this.initUser({
      phone: values.phone && onlyNumbers(values.phone)
    });
  };

  renderAdvantages = () => {
    return (
      <div className="step1__advantages">
        <div className="advantages-item flex">
          <div className="advantages-item__icon">
            <IconClock />
          </div>
          <div className="advantages-item__content flex flex--column">
            <span className="advantages-item__title font-uppercased">
              INSTANT DECISION
            </span>
            <span className="advantages-item__text">
              Provide a few details and get a decision within seconds.
            </span>
          </div>
        </div>

        <div className="advantages-item advantages-item--score flex">
          <div className="advantages-item__icon">
            <IconLock
              style={{
                width: 29,
                height: 33,
              }}
            />
          </div>
          <div className="advantages-item__content flex flex--column">
            <span className="advantages-item__title font-uppercased">
              YOUR SCORE IS SAFE
            </span>
            <span className="advantages-item__text">
              Applying <span className="font-bold font-italic">won’t</span>
              affect your FICO&trade; score.
                <CustomTooltip
                  text={SCORE_TOOLTIP_TEXT}
                />
            </span>
          </div>
        </div>

        <div className="advantages-item flex">
          <div className="advantages-item__icon">
            <IconCards />
          </div>
          <div className="advantages-item__content flex flex--column">
            <span className="advantages-item__title font-uppercased">
              Multiple Payment Options
            </span>
            <span className="advantages-item__text">
              With multiple payment providers, we’ll find a solution for you.
            </span>
          </div>
        </div>
      </div>
    );
  };

  renderAgreedCheckbox = ({ input, meta }) => {
    return (
      <CustomCheckbox 
        meta={meta}
        {...input}
      />
    );
  };

  formatOtpError = (error) => {
    switch (error) {
      case "Incorrect OTP!":
        return "Incorrect code. Try again";
      default:
        return error;
    }
  };

  changePhoneEvent = () => {
    this.setState({phoneError: false})
  }
  changePasscodeEvent = () => {
    this.setState({passcodeError: false})
  }

  renderForm = () => {
    const { valid, handleSubmit } = this.props;
    const { showPasscodeField, loading, passcodeError, phoneError } = this.state;

    return (
      <form onSubmit={handleSubmit(this.submitForm)} className="step1__form paypair-form">
        {!showPasscodeField && (
          <div className="phone-item">
              <div className="p">
                To start your application enter your mobile number below.
              </div>
              <Field
                name="phone"
                validate={[required, isPhoneNumEqual10]}
                component={this.renderPhoneField}
                normalize={normalizePhone}
                onChange={this.changePhoneEvent}
              />
               {phoneError && <div className="field-error phone-error">{phoneError}</div>}
          
          </div> 
        )}
        <div className={showPasscodeField ? "code-item code-item-shown" : "code-item small-height"}>
          <Field
            name="passcode"
            component={this.renderCodeField}
            onChange={this.changePasscodeEvent}
          />
          {passcodeError && <div className="field-error passcode-error">{passcodeError}</div>}
        </div>


        <div className="agreemnet-block">
         
            <Field
              name="isAgreed"
              component={this.renderAgreedCheckbox}
            />
            <div
              className="agreemnet-block__label"
            >
              <span>
                {"By checking this box, you agree to receive recurring automated promotional and personalized marketing text messages (e.g. cart reminders) as well as automated finance and lease approval information from Tire Agent at the cell number used when signing up. Consent is not a condition of any purchase. Reply HELP for help and STOP to cancel. Msg frequency varies. Msg & data rates may apply. "}
                View <a href="http://attn.tv/tireagent/terms.html" target="_blank" >Terms</a> & <a href="https://www.tireagent.com/privacy" target="_blank" >Privacy</a>.</span>
            </div>
         
        </div>


        <BlueBtn 
          loading={loading} 
          disabled={!valid} 
          customClass="step4__recommended-content-btn custom-height-button"
          type="submit">
          Continue
        </BlueBtn>
      </form>
    );
  };

  render() {
    return (
      <div className="step1">
        <h2 className="title2">
          With our all-in-one application, you can quickly and safely see which
          payment plans are best for you.
        </h2>
        <div className="step1__content flex flex__justify--between">
          {this.renderAdvantages()}
          {this.renderForm()}
        </div>
        <TempoeMetrix/>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: tokenSelector()(state),
    isAgreed: selector(state, 'isAgreed'),
    subscribingData: subscribingDataSelector()(state)
  }
};

const mapDistpatchToProps = (dispatch) => {
  return {
    processAuthUserResponse: (value) => dispatch(processAuthUserResponse(value))
  };
};

Step1 = connect(mapStateToProps, mapDistpatchToProps)(Step1);

export default reduxForm({
  form: "form",
  initialValues: { isAgreed: false }, 
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(Step1);

import React, { Component } from "react";
import PropTypes from "prop-types";
import "./styles.scss";

class Frame extends Component {

  constructor(props) {
    super(props)
    window.addEventListener("message", this.props.onReceiveMessage);
  }

  render() {
    const { contractUrl } = this.props
    return ( 
      <div className="iframe-container">
        <div className="scrolldown_request">Please scroll down to continue</div>
        <iframe 
          id="pt-iframe"
          name="provider_flow"
          frameBorder="0"
          border="0" 
          cellSpacing="0"
          src={contractUrl}
        ></iframe>
      </div>
    )
  }
}

Frame.propTypes = {
  contractUrl: PropTypes.string,
  // onReceiveMessage: PropTypes.func
};

Frame.defaultProps = {
  contractUrl: "",
  // onReceiveMessage: () => {}
};

export default Frame;


import React, { PureComponent } from "react";
import ExpandedOfferBlock from "./ExpandedOfferBlock";
import { initApplicationResponseSelector, userInfoSelector, additionalsRequiredApplicationsSelector, additionalsProvidedSelector,
  availableApplicationsSelector, notAvailableApplicationsSelector } from '../selectors';
import { connect } from "react-redux";
import { createStructuredSelector } from 'reselect';
import { addThousandsSeparator } from "utils/helpers";
import { CustomTooltip } from "components/DataDisplay";

import AdditionalDataRequiredOffer from "./AdditionalDataRequiredOffer"; 

import "./styles.scss";

class Step4 extends PureComponent {
  state = {
    additionalInfoBlock: false
  }

  componentDidUpdate(props, state) {
    if (props.initAppResponse.additionals_provided || props.initAppResponse.updated) {
      this.setState({additionalInfoBlock: false})
    }
  }

  componentDidMount() {
    const {
      additionalsRequiredApplications,
      additionalsProvided,
      availableApplications,
      notAvailableApplications,
    } = this.props;

    if (!additionalsProvided && additionalsRequiredApplications.length > 0
                             && availableApplications.length === 0
                             && notAvailableApplications.length > 0
      ) {
      this.setState({additionalInfoBlock: true})
    }

    const insufficientApps = availableApplications.filter(item => item.initial_payment > 50 && item.provider === 'tempoe' )

    if (insufficientApps.length > 0 ) {
      this.setState({additionalInfoBlock: true})
    }
  }

  orderOffers = (offers) => {
    const recommendedList = ['paytomorrow', 'greenwave', 'flexshopper', 'okinus', 'tempoe', 'genesis']
    const orderedOffers = recommendedList.map(recProvider => offers.find(offer => offer.specific_provider_name === recProvider)) 
    return orderedOffers.filter(Boolean)
  }

  render() {
    const {
      initAppResponse,
      availableApplications,
      notAvailableApplications,
      userInfo,
    } = this.props;

    const { additionalInfoBlock } = this.state;

    const orderedOffers = this.orderOffers(availableApplications)

    const isFlexshopperOnly = orderedOffers.length === 1 && orderedOffers[0].provider === 'flexshopper' && orderedOffers[0].authentication_is_required;

    return (
      <div className="step4 paypair-step-content">

        {isFlexshopperOnly && (
          <div>
            <h2 className="subtitle font-center">We've found your Flexshopper account!</h2>
            <h2 className="title2 font-center "></h2>
          </div>
        )}
        {!isFlexshopperOnly && (
          <div>
            <h2 className="subtitle font-center">Congratulations, {userInfo.firstName}!</h2>
            <h2 className="title2 font-center ">
        
              You’ve been approved for your order total of <span className="font-bold step4__response-amount">${addThousandsSeparator(initAppResponse.amount)}</span>
            
              {orderedOffers.find(offer => offer.provider === 'flexshopper') && <CustomTooltip 
                text={`Some finance providers may require additional identity verification questions after you click 'CONTINUE'`} 
              />}
            </h2>
          </div>
         )}

        <div className="step4__content flex flex--column">
          {orderedOffers.map((offer,index) => {
            return (<ExpandedOfferBlock 
              offer={offer} 
              key={index}
            />)
          })}

          { notAvailableApplications.length > 0 && (
            <div>
              <p className="section_title">The plans below are approvals but do not cover your cart total.</p>
              <div className="section_header_message">In order to complete your purchase with these plans, please edit your cart so your total is equal to or less than the approval amount.</div>
              <div className="gray-section">
                <div className="not_enought_amount-block">
                  
                  {notAvailableApplications.map((offer, index) => {
                    return ( <ExpandedOfferBlock 
                      key={index}
                      offer={offer} 
                      available={false}
                    />); 
                  })}
                </div>   
              </div>
            </div> 
          )}
          {additionalInfoBlock && (<AdditionalDataRequiredOffer/>)}
        </div>   
      </div>
    );

  }
}

const mapStateToProps = createStructuredSelector({
  initAppResponse: initApplicationResponseSelector(),
  availableApplications: availableApplicationsSelector(),
  notAvailableApplications: notAvailableApplicationsSelector(),
  additionalsRequiredApplications: additionalsRequiredApplicationsSelector(),
  additionalsProvided: additionalsProvidedSelector(),
  userInfo: userInfoSelector(),
});

Step4 = connect(mapStateToProps)(Step4);

export default Step4;




import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { IconInfo } from "components/Svgs";
import { makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';

import "./styles.scss";

const CustomTooltip = ({ text, title, titleBold }) => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const renderTitle = () => {
    if (title) {
      return titleBold ? <strong className="title">{title}</strong> : <span className="title" >{title}</span>;
    } else {
      return (<IconInfo className="icon"/>);
    }
  }

  let themeStyle = {
      arrow: {
        color: '#fff',
      },
      tooltip: {
        backgroundColor: '#fff',
        color: '#000',
        fontSize: '14px',
        fontFamily: "OpenSans-Regular",
        padding: '10px',
        fontWeight: 'normal'
      }
    }
  

  const useStylesBootstrap = makeStyles((theme) => (themeStyle))
  const classes = useStylesBootstrap();

  return (
    <div className="custom-tooltip">
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip 
          arrow 
          classes={classes}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          TransitionComponent={Fade} 
          TransitionProps={{ timeout: 600 }}
          title={text}
        >
          <div onClick={handleTooltipOpen}>
            {renderTitle()}
          </div>
        </Tooltip>
      </ClickAwayListener>
    </div>
  );
}

export default CustomTooltip;
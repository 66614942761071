import React, { Component } from "react";
import { IconLockFooter } from "components/Svgs";
import { PRIVACY_TOOLTIP_TEXT } from './constants';
import { CustomTooltip } from "components/DataDisplay";

import "./styles.scss";

class ModalFooter extends Component {
  render() {
    const tooltipText = "All data is encrypted and stored securely<br/> using the industry’s best standards.";
    return (
      <div className="multistep-modal-footer">
        <div className="multistep-modal-footer__text step1-notification">
          Powered by PayPair
        </div>

        <div className=" flex flex__align--baseline flex__justify--center">

          <div className="multistep-modal-footer__icon">
            <IconLockFooter
              style={{
                width: "100%",
                height: "100%"
              }}
            />
          </div>
      
          <span className="notice_message">
            Your personal information is encrypted and secured
            <CustomTooltip text={PRIVACY_TOOLTIP_TEXT}/>
          </span>
        
        </div>
      </div>
    );
  }
}

export default ModalFooter;

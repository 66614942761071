import PayTommorowLogo from "assets/img/paytomorrow-logo.png";
import ViaBillLogo from "assets/img/viabill-logo.png";
import SezzleLogo from "assets/img/sezzle-logo.png";
import FlexshopperLogo from "assets/img/FlexShopper-logo-color.png";
import TempoeLogo from "assets/img/tempoe-logo.png";
import OkinusLogo from "assets/img/okinus-logo.png";
import GreenWaveLogo from "assets/img/greenwave-logo.png";
import GenesisLogo from "assets/img/genesis-logo.png";

export const providerLogos = {
  paytomorrow: { img: PayTommorowLogo },
  flexshopper: { img: FlexshopperLogo },
  acima: { img: null },
  tempoe: { img: TempoeLogo },
  viabill: { img: ViaBillLogo },
  sezzle: { img: SezzleLogo },
  okinus: { img: OkinusLogo },
  greenwave: { img: GreenWaveLogo },
  genesis: { img: GenesisLogo }
};

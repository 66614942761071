import React, { Component } from "react";
import { TextBtn, CustomCheckbox, BlueBtn } from "components/Controls";
import { LogoView, AgreementLink } from "components/DataDisplay";
import { connect } from "react-redux";
import { selectedOfferSelector, userInfoSelector, loadingSelector, activeStepDataSelector } from '../../selectors';
import { confirmOffer, backToOffers, nextStep } from "../../actions";
import { BuyoutInfo, OfferView } from 'components/DataDisplay';
import { Field, reduxForm } from "redux-form";
import Modal from "@material-ui/core/Modal";
import { IconBtn } from "components/Controls";
import { IconClose } from "components/Svgs";
import FlexshopperContract from "../../ContractStep/FlexshopperContract";
import { formValueSelector } from 'redux-form';
const selector = formValueSelector('confirmation_step_form');
import { required } from "utils/validations";
import "./styles.scss";
import { ApplicationAPI } from 'api/application';
import { tokenSelector } from "containers/EmbeddableWidget/selectors";

class GenesisConfirmation1 extends Component {
  state = {
    isTermsOpen: false,
    isTermsReviewed: false,
    isFEBOpen: false,
    isFEBReviewed: true,
    loading: false
  };

  reviewTerms = () => {
    this.setState({ isTermsReviewed: true });
  };

  reviewFEB = () => {
    this.setState({ isFEBOpen: true });
  };

  handleCloseTerms = () => {
    this.setState({ 
      isTermsOpen: false, 
      isTermsReviewed: true
    });
  };  
  
  handleCloseFEB = () => {
    this.setState({ 
      isFEBOpen: false, 
      isFEBReviewed: true
    });
  };

  renderAgreement = () => {
    const { isFEBOpen, isTermsOpen } = this.state;

    return (
      <div>
        <Modal
          className="multistep-modal"
          aria-labelledby="multistep-modal-title"
          aria-describedby="multistep-modal-description"
          open={isTermsOpen}
          onClose={this.handleCloseTerms}
        >
          <div className="multistep-modal__wrapper">
          <React.Fragment>
            <IconBtn
              customClass="multistep-modal__close-btn"
              onClick={this.handleCloseTerms}
            >
              <IconClose style={{ width: 14, height: 14 }} />
            </IconBtn>
            Some content
            <div className="multistep-modal-footer flex flex__align--baseline flex__justify--end">
              <div className="multistep-modal-footer__icon"></div>
              <span className="font-italic multistep-modal-footer__text">
              </span>
            </div>
          </React.Fragment>
          </div>
        </Modal>
        <Modal
          className="multistep-modal"
          aria-labelledby="multistep-modal-title"
          aria-describedby="multistep-modal-description"
          open={isFEBOpen}
          onClose={this.handleCloseFEB}
        >
          <div className="multistep-modal__wrapper">
          <React.Fragment>
            <IconBtn
              customClass="multistep-modal__close-btn"
              onClick={this.handleCloseFEB}
            >
              <IconClose style={{ width: 14, height: 14 }} />
            </IconBtn>
            Some content
            <div className="multistep-modal-footer flex flex__align--baseline flex__justify--end">
              <div className="multistep-modal-footer__icon"></div>
              <span className="font-italic multistep-modal-footer__text">
              </span>
            </div>
          </React.Fragment>
          </div>
        </Modal>
      </div>
    );
  }

  valid = () => {
    const { isTermsReviewed, isFEBReviewed } = this.state;
    return  isTermsReviewed && isFEBReviewed;
  };
   
  handleContinueButton = () => {
    const { token, nextStep, data} = this.props
    this.setState({loading: true})
    ApplicationAPI.accept_the_offer(
      token,
      data.app_id, 
      {}
    ).then(data => {
      if ( data.errors ) {
        return nextStep({step: 'nobodyApprovedStep'});
      }
      nextStep({step: 'genesisConfirmation2', data: data});
    }).catch(err => {

    })
  };

  handleBackButton = () => {
    const { backToOffers } = this.props;
    backToOffers();
  };

  render() {
    const { userInfo, data } = this.props;
    const { loading } = this.state;

    return (
      <div className="genesis_confirmation_step step5__content flex--column">
        <div className="flex flex--column"> 
          <TextBtn
            customClass="step5__change-btn"
            onClick={this.handleBackButton}
          >
            see more
          </TextBtn>
          <h2 className="subtitle font-center">
            Congratulations, {userInfo.firstName}!
          </h2>
          <div className="subtitle2 font-center" >Your have been pre-approved for a <b>Genesis Credit Account</b></div>
          <div className="subtitle2 font-center" >Use your Genesis Credit Account to pay for your order of <b>${data.requested_amount}</b></div>
        </div>

        <LogoView provider={data.provider}/>
        <div className="box">
          <div>You need to read the disclosures before accepting the offer: </div>
          <a href={data.disclosure_link} target="_blank" className="link"  onClick={this.reviewTerms}>
            Click to revew Terms and Conditions for the Genesis Credit Account
          </a>
          <br/>
          {/* <a href="#" className="link "  onClick={this.reviewFEB}>
            Click to review the FEB loan acceptance disclosure
          </a> */}
        </div>

        {this.renderAgreement()}
        
        <BlueBtn
          disabled={!this.valid()}
          customClass="step5__submit-btn"
          onClick={this.handleContinueButton}
          loading={loading}
        >
          ACCEPT OFFER AND PURCHASE
        </BlueBtn>

        <div className="post_info">If approved, the remaining available credit of <b>${data.approval_amount - data.requested_amount}</b> may be used towards future purchases at Tire Agent, subject to credit available</div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAgreementChecked: selector(state, 'isAgreementChecked'),
  token: tokenSelector()(state),
  userInfo: userInfoSelector()(state),
  data: activeStepDataSelector()(state)
});

const mapDistpatchToProps = dispatch => {
  return {
    confirmOffer: () => dispatch(confirmOffer()),
    backToOffers: () => dispatch(backToOffers()),
    nextStep: value => dispatch(nextStep(value))
  };
};

GenesisConfirmation1 = connect(mapStateToProps, mapDistpatchToProps)(GenesisConfirmation1);
GenesisConfirmation1 = reduxForm({
  form: "form",
  initialValues: { isAgreementChecked: false }, 
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(GenesisConfirmation1);

export default GenesisConfirmation1;

import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import Modal from "@material-ui/core/Modal";
import { IconBtn, BlueBtn } from "components/Controls";
import { IconClose } from "components/Svgs";
import ModalHeader from "./ModalHeader";
import ModalFooter from "./ModalFooter";
import { modalOpenedSelector } from "./selectors";
import { openModal, closeModal } from "./actions";
import { createStructuredSelector } from "reselect";

import MultistepForm from "../MultistepForm";
import {
  activeStepSelector,
  applicationHasContractSelector,
  selectedOfferSignedSelector,
} from "containers/MultistepForm/selectors";
import { cancelApplication } from "containers/MultistepForm/actions";

import "./styles.scss";

class MultistepModal extends Component {
  handleOpenModal = () => {
    this.props.openModal();
  };

  handleCloseModal = () => {
    const {
      applicationHasContract,
      closeModal,
      cancelApplication,
      applicationSigned,
    } = this.props;
    // if (applicationHasContract && !applicationSigned) {
    //   cancelApplication();
    // }
    closeModal();
  };

  componentDidMount = () => {
    this.handleOpenModal();
  };

  renderModalContent = () => {
    const { activeStep } = this.props;

    return (
      <React.Fragment>
        <IconBtn
          customClass="multistep-modal__close-btn"
          onClick={this.handleCloseModal}
        >
          <IconClose style={{ width: 14, height: 14 }} />
        </IconBtn>
        <ModalHeader />
        <MultistepForm />
        <ModalFooter />

      </React.Fragment>
    );
  };

  render() {
    const { modalOpened } = this.props;
    return (
      <div>
        {/* <BlueBtn onClick={this.handleOpenModal}>Open Modal</BlueBtn> */}
        <Modal
          className="multistep-modal"
          aria-labelledby="multistep-modal-title"
          aria-describedby="multistep-modal-description"
          open={modalOpened}
          onClose={this.handlCloseModal}
        >
          <div className="multistep-modal__wrapper">
            {this.renderModalContent()}
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  modalOpened: modalOpenedSelector(),
  activeStep: activeStepSelector(),
  applicationHasContract: applicationHasContractSelector(),
  applicationSigned: selectedOfferSignedSelector(),
});

const mapDistpatchToProps = (dispatch) => {
  return {
    openModal: () => dispatch(openModal()),
    closeModal: () => dispatch(closeModal()),
    cancelApplication: () => dispatch(cancelApplication()),
  };
};

MultistepModal = connect(mapStateToProps, mapDistpatchToProps)(MultistepModal);

export default reduxForm({
  form: "form",
})(MultistepModal);

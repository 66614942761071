import React, { Component } from "react";
import { selectedOfferSelector } from '../selectors';
import { connect } from "react-redux";
import { createStructuredSelector } from 'reselect';
import "./styles.scss";

class SuccessStep extends Component {

  providerName = () => {
    const names = {
      flexshopper: "FlexShopper",
      paytomorrow: "PayTomorrow",
      greenwave: "GreenWave",
      tempoe: "Tempoe"
    }
    const { selectedOffer } = this.props
    return names[selectedOffer.specific_provider_name] || ""
  }
  render() { 
    return (
      <div className="text-center">
        <h2>Thank you!</h2> 
        <p>Your lease was signed successfully.</p>
        <p>Thank you for using {this.providerName()}</p>
        <p>We hope you enjoy your purchase</p>
      </div>
    );
  }
};

const mapStateToProps = createStructuredSelector({
  selectedOffer: selectedOfferSelector()
});

SuccessStep = connect(mapStateToProps)(SuccessStep);

export default SuccessStep;

/* eslint-disable arrow-body-style, no-shadow */

import _ from "lodash";

const isPhoneNumEqual = num => value => {
  value = value.replace(/[^\d]/g, "");
  if (value && value.length !== num) {
    return `Phone number must be ${num} digits long`;
  }

  return undefined;
};

const isNumEqual = (num, message) => value => {
  if (!value) {
    return undefined
  }
  
  value = value.replace(/[^\d]/g, "");
  if (value && value.length !== num) {
    return message;
  }

  return undefined;
};

const isLessThan = (num, message) => value => {
  if (value && value.length >= num) {
    return message;
  }

  return undefined;
};

export const required = value =>
  value || typeof value === "number" ? undefined : "required";

export const isPhoneNumEqual10 = isPhoneNumEqual(10); 

export const isCodeEqual6 = isNumEqual(6, "Code should be 6 digits long");

export const isNumEqual9 = isNumEqual(9, "Number must be 9 digits long");

export const isNumEqual14 = isNumEqual(14, "Number must be 9 digits long");

export const isLessThan20digits = isLessThan(
  20,
  "Should not exceed 20 digits long."
);

export const isLessThan20characters = isLessThan(
  20,
  "Should not exceed 20 characters long."
);

export const onlyNumber = value => {
  // value = value.replace(/[^\d]/g, "");
  if (value && isNaN(Number(value))) {
    return "Must be a number";
  }
  return undefined;
};

export const onlyPositiveInteger = value => {
  value = value && Number(value) ? Number(value) : null;

  if (_.isNull(value) || !Number.isInteger(value) || value <= 0) {
    return "only positive";
  }

  return undefined;
};

export const greaterOrEqualZero = value => {
  if (!value) {
    return undefined;
  }
  if (value >= 0) {
    return undefined;
  }

  return "only numbers";
};

export const greaterOrEqualZeroIncome = value => {
  value = value.replace(/[$]/g, "");

  if (!Number(value)) {
    return undefined;
  }
  if (Number(value) >= 0) {
    return undefined;
  }
  return "only numbers";
};

const isNumGreaterThan = (num, message) => value => {
  if (!value) {
    return undefined
  }

  value =  value.replace(/[^0-9 ]/g, "")
  
  if (value && Number(value) > num) {
    return message;
  }

  return undefined;
};

export const isNumGreaterThan50000 = isNumGreaterThan(50000, "Max amount is $50,000");


export const onlyNumberAndLetters = value => {
  const rexExp = /^[a-zA-Z0-9]+$/;

  if (value && !rexExp.test(value)) {
    return "Can be letters and digits";
  }

  return undefined;
};

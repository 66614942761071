import React, { Component } from "react";
import { connect } from "react-redux";
import Frame from "../Frame";
import { finishApplication } from "../../actions";
import PropTypes from "prop-types";
class TempoeContract extends Component {

  receiveMessage = (event) => {
    const { finishApplication } = this.props
    if (!event.origin.match(/tempoe/))
      return;
    switch (event.data) {
      case "tempoe_lease_finalized":
        finishApplication();
        break;
    }
  } 

  render() {
    const { contractUrl } = this.props
    return ( 
      <Frame 
        contractUrl={contractUrl}
        onReceiveMessage={this.receiveMessage}
      />
    )
  }
}

TempoeContract.propTypes = {
  contractUrl: PropTypes.string
};

TempoeContract.defaultProps = {
  contractUrl: ""
};

const mapDistpatchToProps = dispatch => {
  return {
    finishApplication: values => dispatch(finishApplication(values))
  };
};

TempoeContract = connect(null, mapDistpatchToProps)(TempoeContract);

export default TempoeContract;

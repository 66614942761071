import { ActionTypes } from './actionTypes';

export const init = payload => ({
  type: ActionTypes.init,
  payload
});

export const initUserData = payload => ({
  type: ActionTypes.initUserData,
  payload
});

export const initApplication = payload => ({
  type: ActionTypes.initApplication,
  payload
});

export const initApplicationSuccess = payload => ({
  type: ActionTypes.initApplicationSuccess,
  payload
})

export const initApplicationFailed = () => ({
  type: ActionTypes.initApplicationFailed 
});

export const continueInitApplication = payload => ({
  type: ActionTypes.continueInitApplication,
  payload
});

export const continueInitApplicationSuccess = payload => ({
  type: ActionTypes.continueInitApplicationSuccess,
  payload
})

export const continueInitApplicationFailed = () => ({
  type: ActionTypes.continueInitApplicationFailed 
})

export const backToOffers = () => ({
  type: ActionTypes.backToOffers
})

export const signApplicationSuccess = payload => ({
  type: ActionTypes.signApplicationSuccess,
  payload
})

export const signApplicationFailed = payload => ({
  type: ActionTypes.signApplicationFailed,
  payload
})

export const finishApplication = payload => ({
  type: ActionTypes.finishApplication,
  payload
})

export const finishApplicationFailed = payload => ({
  type: ActionTypes.finishApplicationFailed,
  payload
})

export const finishApplicationSuccess = payload => ({
  type: ActionTypes.finishApplicationSuccess,
  payload
})

export const cancelApplication = payload => ({
  type: ActionTypes.cancelApplication,
  payload
})

export const nextStep = payload => ({
  type: ActionTypes.nextStep,
  payload
})

export const initUser = payload => ({
  type: ActionTypes.initUser,
  payload
})

export const initUserSuccess = payload => ({
  type: ActionTypes.initUserSuccess,
  payload
})

export const initUserFailed = payload => ({
  type: ActionTypes.initUserFailed,
  payload
})

export const authUser = payload => ({
  type: ActionTypes.authUser,
  payload
})

export const authUserSuccess = payload => ({
  type: ActionTypes.authUserSuccess,
  payload
})

export const authUserFailed = payload => ({
  type: ActionTypes.authUserFailed,
  payload
});

export const updateApplication = payload => ({
  type: ActionTypes.updateApplication,
  payload
});

export const processApplicationResponse = payload => ({
  type: ActionTypes.processApplicationResponse,
  payload
});

export const processAuthUserResponse = payload => ({
  type: ActionTypes.processAuthUserResponse,
  payload
});

export const updateCompletedApplication = payload => ({
  type: ActionTypes.updateCompletedApplication,
  payload
});

export const selectOffer = payload => ({
  type: ActionTypes.selectOffer,
  payload
});

export const selectOfferSuccess = payload => ({
  type: ActionTypes.selectOfferSuccess,
  payload
});

export const selectOfferFailed = () => ({
  type: ActionTypes.selectOfferFailed 
});

export const confirmOffer = payload => ({
  type: ActionTypes.confirmOffer,
  payload
});

export const confirmOfferSuccess = payload => ({
  type: ActionTypes.confirmOfferSuccess,
  payload
});

export const confirmOfferFailed = () => ({
  type: ActionTypes.confirmOfferFailed 
});

export const setMetrixSession = payload => ({
  type: ActionTypes.setMetrixSession,
  payload
});

export const setSelectedOffer = payload => ({
  type: ActionTypes.setSelectedOffer,
  payload
})
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./styles.scss";

const ExpandBtn = ({ disabled, isExpanded, customClass, ...props }) => (
  <button
    disabled={disabled}
    type="button"
    className={classNames(
      "font-uppercased button flex flex__justify--center",
      "button-expand",
      `${customClass}`,
      {
        expanded: isExpanded
      }
    )}
    {...props}
  >
    {props.children}
    <div className="button-expand__icon"></div>
  </button>
);

ExpandBtn.propTypes = {
  children: PropTypes.any,
  customClass: PropTypes.string,
  disabled: PropTypes.bool,
  isExpanded: PropTypes.bool.isRequired
};

export default ExpandBtn;

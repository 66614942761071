import React, { Component } from "react";
import { TextBtn, CustomCheckbox, BlueBtn } from "components/Controls";
import { LogoView, AgreementLink } from "components/DataDisplay";
import { connect } from "react-redux";
import { selectedOfferSelector, userInfoSelector, loadingSelector, selectedOfferAcceptedSelector } from '../selectors';
import { confirmOffer, backToOffers } from "../actions";
import { BuyoutInfo, OfferView } from 'components/DataDisplay';
import { Field, reduxForm } from "redux-form";
import Modal from "@material-ui/core/Modal";
import { IconBtn } from "components/Controls";
import { IconClose } from "components/Svgs";
import FlexshopperContract from "../ContractStep/FlexshopperContract";
import { formValueSelector } from 'redux-form';
const selector = formValueSelector('confirmation_step_form');
import { required } from "utils/validations";
import "./styles.scss";

class ConfirmationStep extends Component {
  state = {
    isAgreementOpen: false,
    isAgreementReviewed: false
  };

  reviewAgreement = () => {
    this.setState({ isAgreementOpen: true });
  };

  handleCloseAgreement = () => {
    this.setState({ 
      isAgreementOpen: false, 
      isAgreementReviewed: true
    });

  }

  renderOffer = () => {
    const { selectedOffer } = this.props;

    return (
      <div className="step5__offer offer-wrapper flex flex--column flex__align--center">
        <div className="step5__offer-logo ">
          <LogoView provider={selectedOffer.specific_provider_name}/>
        </div>
        <span className="step5__offer-title font-center">
          <strong>HERE’S THE BREAKDOWN OF YOUR PAYMENT PLAN</strong>
        </span>
        <div className="step5__offer-plan flex flex--wrap application-offer">
          <OfferView 
            offer={selectedOffer}
            preview={true}
          />
        </div>

        {selectedOffer.type == "Lease to Loan" && selectedOffer.buyout && <BuyoutInfo 
          title={"Early Buyout Option"}
          text={`Payoff in 90days and only pay a ${selectedOffer.buyout_rate}% fee`}
        />}
        {selectedOffer.specific_provider_name == 'greenwave' && <BuyoutInfo 
          title={"90-Day Payment Option"}
          text={`Simply pay the cash price within 90 days after the lease is completed.`}
        />}
      </div>
      
    );
  };

  renderModalContent = () => {
    const { selectedOffer } = this.props;

    return (
      <React.Fragment>
        <IconBtn
          customClass="multistep-modal__close-btn"
          onClick={this.handleCloseAgreement}
        >
          <IconClose style={{ width: 14, height: 14 }} />
        </IconBtn>
        <FlexshopperContract contract={selectedOffer.contract}/>
        <div className="multistep-modal-footer flex flex__align--baseline flex__justify--end">
          <div className="multistep-modal-footer__icon">

          </div>

          <span className="font-italic multistep-modal-footer__text">
          </span>
        </div>
      </React.Fragment>
    );
  };

  renderAgreementCheckbox = ({ input, meta }) => {
    return (
      <CustomCheckbox 
        meta={meta}
        {...input}
      />
    );
  };

  renderFlexshopperAgreement = () => {
    const { isAgreementReviewed, isAgreementOpen } = this.state;

    return (
      <div>
        <AgreementLink
          isAgreementReviewed={isAgreementReviewed}
          action={this.reviewAgreement}
          linkText={'Click here to review the FlexShopper Lease Agreement before completing your order.'}
        />
        <Modal
          className="multistep-modal"
          aria-labelledby="multistep-modal-title"
          aria-describedby="multistep-modal-description"
          open={isAgreementOpen}
          onClose={this.handleCloseAgreement}
        >
          <div className="multistep-modal__wrapper">
            {this.renderModalContent()}
          </div>
        </Modal>

        <div className="step5__agreemnet-block">
          <div className="flex flex__align--center">
  
            <Field
              name="isAgreementChecked"
              validate={[required]}
              component={this.renderAgreementCheckbox}
            />
            <div
              className="agreemnet-block__label"
            >
              {"I have read and agree to the "}
              <b>FlexShopper </b>
              <a href="#" className="link "  onClick={this.reviewAgreement}>
                Lease Agreement
              </a>
              .
            </div>
          </div>


        </div>
      </div>
    );
  }

  valid = () => {
    const { selectedOffer, isAgreementChecked } = this.props;
    const { isAgreementReviewed } = this.state;

    if(selectedOffer.provider === 'flexshopper') {
      return  isAgreementReviewed && isAgreementChecked;
    }
    return true;
  };
   
  handleContinueButton = () => {
    this.props.confirmOffer();
  };

  handleBackButton = () => {
    const { backToOffers } = this.props;
    backToOffers();
  };

  render() {
    const { selectedOffer, userInfo } = this.props;
    const { loading } = this.props;

    if (!selectedOffer) {
      return (<p className="text-center">No offer was selected.</p>);
    }
    return (
      <div className="step5__content flex--column">
        <div className="flex flex--column-reverse"> 
          <TextBtn
            customClass="step5__change-btn"
            onClick={this.handleBackButton}
          >
            see more
          </TextBtn>
          <h2 className="subtitle font-center">
            Congratulations, {userInfo.firstName}!
          </h2>
        </div>

        {this.renderOffer()}

        {selectedOffer.provider === 'flexshopper' &&  this.renderFlexshopperAgreement()}
        
        <BlueBtn
          disabled={!this.valid()}
          customClass="step5__submit-btn"
          onClick={this.handleContinueButton}
          loading={loading}
        >
          CONTINUE TO LAST STEP
        </BlueBtn>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAgreementChecked: selector(state, 'isAgreementChecked'),
  selectedOffer: selectedOfferSelector()(state),
  // selectedOfferAlreadyAccepted: selectedOfferAcceptedSelector()(state),
  userInfo: userInfoSelector()(state),
  loading: loadingSelector()(state)
});

const mapDistpatchToProps = dispatch => {
  return {
    confirmOffer: () => dispatch(confirmOffer()),
    backToOffers: () => dispatch(backToOffers()),
  };
};

ConfirmationStep = connect(mapStateToProps, mapDistpatchToProps)(ConfirmationStep);
ConfirmationStep = reduxForm({
  form: "confirmation_step_form",
  initialValues: { isAgreementChecked: false }, 
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(ConfirmationStep);

export default ConfirmationStep;

import React from "react";
import PropTypes from "prop-types";
import { CustomTooltip } from "components/DataDisplay";
import { CustomRadioGroup } from "components/Controls";
import "./styles.scss";

const RadioFieldWithTooltip = ({
  label,
  tooltipText,
  tooltipTitle,
  ...props
}) => {


  return (
    <div className="radio-field-component flex flex--column">
      <label className="font-uppercased radio-field-label">
        <span className="tooltip-box">
          <span dangerouslySetInnerHTML={{__html: label}}></span>
          <CustomTooltip
            text={tooltipText} 
            title={tooltipTitle}
          />
        </span>
      </label>
      <CustomRadioGroup 
        {...props}
       />
    </div>
  );
};

RadioFieldWithTooltip.propTypes = {
  label: PropTypes.string
};

export default RadioFieldWithTooltip;

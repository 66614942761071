/* eslint-disable no-unused-expressions */
import React from "react";

const IconChevron = props => (
  <svg {...props} width="14" height="10" viewBox="0 0 14 10">
    <g
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M7 10L12 5 7 0M0 10L5 5 0 0" transform="translate(1)" />
    </g>
  </svg>
);

export default IconChevron;

/* eslint-disable no-unused-expressions */
import React from "react";

const IconCards = () => (
  <svg 
    width="42"
    height="29"
    viewBox="0 0 42 29"
    className="svg-icon"
  >
    <defs>
      <filter
        id="prefix__a"
        width="140.5%"
        height="162.5%"
        x="-20.3%"
        y="-31.2%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dx="3" dy="3" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feColorMatrix
          in="shadowOffsetOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0.831554878 0 0 0 0 0.882088415 0 0 0 0 1 0 0 0 1 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g
      fill="none"
      fillRule="evenodd"
      stroke="#3E70FC"
      filter="url(#prefix__a)"
      transform="translate(1 1)"
    >
      <g transform="translate(9)">
        <rect width="28" height="15" fill="#FFF" strokeWidth="1.8" rx="1" />
        <path d="M0 2.786L28 2.786M0 4.688L28 4.688M3 8.438H8V10.313H3z" />
        <ellipse cx="23.5" cy="10.929" strokeWidth=".8" rx="1.5" ry="1.406" />
        <path
          strokeWidth=".8"
          d="M22.686 9.759c-.912-.518-2.409-.121-2.347 1.17.061 1.292 1.7 1.6 2.347.98"
        />
        <path strokeLinecap="square" d="M3 12.336L11 12.336" />
      </g>
      <g transform="translate(0 9)">
        <rect width="28" height="15" fill="#FFF" strokeWidth="1.8" rx="1" />
        <path d="M0 2.786L28 2.786M0 4.688L28 4.688M3 8.438H8V10.313H3z" />
        <ellipse cx="23.5" cy="10.929" strokeWidth=".8" rx="1.5" ry="1.406" />
        <path
          strokeWidth=".8"
          d="M22.686 9.759c-.912-.518-2.409-.121-2.347 1.17.061 1.292 1.7 1.6 2.347.98"
        />
        <path strokeLinecap="square" d="M3 12.336L11 12.336" />
      </g>
    </g>
  </svg>
);

export default IconCards;

import React, { Component } from "react";
import { LogoView } from "components/DataDisplay";
import { BlueBtn, IconBtn } from "components/Controls";
import { IconBack } from "components/Svgs";
import "./styles.scss";

import { userInfoSelector } from '../selectors';
import { connect } from "react-redux";
import { createStructuredSelector } from 'reselect';
import { closeModal } from "../../MultistepModal/actions";

class NobodyApprovedStep extends Component {
  render() {
    const { userInfo, closeModal } = this.props;

    return (
      <div className="nobody-approved-step flex flex--column flex__align--center ">
        <span className="subtitle font-center">Hi, {userInfo.firstName}!</span>

        <span className="nobody-approved-step__text">
          Your application was not approved by some of the financing companies
          but don’t worry - we have another option!
        </span>
        <span className="nobody-approved-step__option-title">
          We’d like to introduce Viabill.
        </span>
        <div className="nobody-approved-step__option-wrapper">
          <LogoView provider="viabill"/>
          <ul className="nobody-approved-step__option-info">
            <li className="nobody-approved-step__info-item">
              <span className="info-item-text">Monthly Payments</span>
            </li>
            <li className="nobody-approved-step__info-item">
              <span className="info-item-text"> Pay over 4 Months</span>
            </li>
            <li className="nobody-approved-step__info-item">
              <span className="info-item-text">No Interest</span>
            </li>

            <li className="nobody-approved-step__info-item">
              <span className="info-item-text"> 
                <span className="link">No Credit Checks</span> 
                for Orders up to
                $1,000
              </span>
            </li>
            <li className="nobody-approved-step__info-item">
              <span className="info-item-text">
                25% of Purchase Amount due at Checkout
              </span>
            </li>
          </ul>
          <BlueBtn onClick={closeModal}> APPLY TO VIABILL </BlueBtn>
        </div>
        <IconBtn customClass="nobody-approved-step__back-btn" onClick={closeModal} >
          <IconBack /> 
          BACK TO PAYMENT OPTIONS
        </IconBtn>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userInfo: userInfoSelector()
});

const mapDistpatchToProps = dispatch => {
  return {
    closeModal: () => dispatch(closeModal())
  };
};

NobodyApprovedStep = connect(mapStateToProps, mapDistpatchToProps)(NobodyApprovedStep);

export default NobodyApprovedStep;

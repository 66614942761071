import { mergeDeepRight } from 'ramda';
import { ActionTypes } from './actionTypes';
import { applicationResponseStub, appRequest, greenWaveSelectedOffer, genesisSelectedOffer, flexshopperSelectedOffer, paytomorrowSelectedOffer, tempoeSelectedOffer } from 'utils/stubData';
import * as ModalActionTypes from '../MultistepModal/actionTypes';

const applicationResponseDs = {
  request_id: null,
  amount: 0,
  offers: []
}

const defaultState = {
  activeStep: 'form1',
  activeStepData: {},
  applicationRequest: {
    metrixSessionId: null,
    personalInfo: {
      email: null,
      firstName: null,
      lastName: null,
      phone: null,
      dob: null,
      ssn: null
    },
    residenceInfo: {
      street: null,
      country: null,
      city: null,
      state: null,
      zip: null,
    },
    orderId: null,
    shipping: 0,
    items: [],
    has_additional_info: false,
    additional_info_data: {},
    employmentInformation: {
      payFrequency: null, 
      monthlyIncome: 0,
      last_payday_on: null,
      next_payday_on: null,
      direct_deposit: true
    }
  },
  requestId: null,
  applicationResponse: applicationResponseDs,
  selectedOffer: null,
  selectedOfferSigned: false,
  loading: false, 
};

export default (state = defaultState, action) => {
  let activeStep = null;
  switch (action.type) {
    case ModalActionTypes.CLOSE_MODAL:
      return mergeDeepRight(state, { 
        loading: false, 
        selectedOffer: null,
        applicationResponse: null,
        requestId: null,
        applicationRequest: action.payload,
        selectedOfferSigned: false,
        activeStep: 'form1'
      })
    case ActionTypes.setMetrixSession:
      return mergeDeepRight(state, {applicationRequest: {
        metrixSessionId: action.payload
      } })
    case ActionTypes.initUserData:
      return mergeDeepRight(state, { 
        loading: false, 
        selectedOffer: null,
        applicationResponse:applicationResponseDs,// applicationResponseStub,
        requestId: null,
        applicationRequest: mergeDeepRight(defaultState.applicationRequest, action.payload), //appRequest
        selectedOfferSigned: false,
        activeStep: 'form1'
      })

    case ActionTypes.initApplication:
    case ActionTypes.continueInitApplication:
      return mergeDeepRight(state, { 
        loading: true, 
        applicationRequest: action.payload.values 
      });
    case ActionTypes.initApplicationFailed:
      return mergeDeepRight(state, { 
        loading: false,
        activeStep: 'nobodyApprovedStep',
        applicationResponse: null
      }); 

    case ActionTypes.initApplicationSuccess:
      return mergeDeepRight(state, {  
        loading: false,
        applicationResponse: action.payload.data,
        requestId: action.payload.data.request_id,
        selectedOffer: null
      });
    case ActionTypes.continueInitApplicationSuccess: 
      const providersToUpdate = action.payload.data.offers.map(offer => offer.provider)
      const keepPrevOffers = state.applicationResponse.offers.filter(offer => providersToUpdate.indexOf(offer.provider) ==-1)
      const mergedOffers = keepPrevOffers.concat(action.payload.data.offers)
      return mergeDeepRight(state, {  
        loading: false,
        applicationResponse: {
          additionals_provided: action.payload.data.additionals_provided,
          offers: mergedOffers
        },
        selectedOffer: null
      });
    case ActionTypes.setSelectedOffer:
      return mergeDeepRight(state, { 
        selectedOffer: action.payload
      })
    case ActionTypes.selectOffer: 
      if (action.payload.selectedOffer) {
        return mergeDeepRight(state, { 
          selectedOffer: action.payload.selectedOffer, 
          loading: true,
        });
      }
    case ActionTypes.confirmOffer:
      return mergeDeepRight(state, { 
        loading: true,
      });

    // contract can come(flexshopper), so merge it into selectedOffer
    case ActionTypes.selectOfferSuccess:
      return mergeDeepRight(state, { 
        selectedOffer: action.payload, 
        loading: false,
      });

    case ActionTypes.confirmOfferSuccess:
      return mergeDeepRight(state, { 
        selectedOffer: action.payload, 
        activeStep: state.selectedOffer.provider === 'paytomorrow' ? 'contractStep' : 'confirmationStep',
        loading: false,
      });
      
    case ActionTypes.cancelApplication:
      return mergeDeepRight(state, { });
    case ActionTypes.backToOffers:
      return mergeDeepRight(state, { selectedOffer: null, activeStep: 'form4' });

    case ActionTypes.finishApplicationSuccess:
      return mergeDeepRight(state, { 
        selectedOfferSigned: true, 
        loading: false 
      });
    case ActionTypes.selectOfferFailed:
      if (state.selectedOffer.provider === 'flexshopper') {
        return mergeDeepRight(state, { 
          activeStep: 'errorStep', 
          activeStepData: {message: 'There was an error creating your FlexShopper lease agreement, please try to check out with PayPair again'},
          loading: false 
        });
      } else {
        return mergeDeepRight(state, { activeStep: 'nobodyApprovedStep', loading: false });
      }
    case ActionTypes.confirmOfferFailed:
    case ActionTypes.finishApplicationFailed:
    case ActionTypes.signApplicationFailed:
      return mergeDeepRight(state, { activeStep: 'nobodyApprovedStep', loading: false });
    case ActionTypes.processAuthUserResponse:
      return mergeDeepRight(state, { 
        requestId: action.payload.response.request ? action.payload.response.request.id : null,
        applicationRequest: {
          personalInfo: {
            phone: action.payload.request.phone
          }
        }
      });
    case ActionTypes.nextStep:
      return mergeDeepRight(state, { 
        activeStep: action.payload.step, 
        activeStepData: action.payload.data || {}, 
        loading: false 
      });
    case ActionTypes.updateApplication:
      return mergeDeepRight(state, { 
        applicationResponse: { updated: true }
      });
    default:
      return mergeDeepRight(state, { });
  }
}

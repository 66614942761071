import React, { Component } from "react";
import { createStructuredSelector } from 'reselect';
import { selectedOfferSelector } from '../selectors';
import { connect } from "react-redux";
import PaytomorrowContract from "./PaytomorrowContract";
import FlexshopperContract from "./FlexshopperContract";
import TempoeContract from "./TempoeContract";

class ContractStep extends Component {

  renderPaytomorrowContract = () => {
    const { selectedOffer } = this.props
    return (<PaytomorrowContract
      contractUrl={selectedOffer.contractUrl}
    />);
  };

  renderFlexshopperContract = () => {
    const { selectedOffer } = this.props
    return (<FlexshopperContract
      contract={selectedOffer.contract}
    />);
  };

  renderTempoeContract = () => {
    const { selectedOffer } = this.props
    return (<TempoeContract
      contractUrl={selectedOffer.contractUrl}
    />);
  };

  renderSwitch = (provider) => {
    switch(provider) {
      case 'paytomorrow':
        return this.renderPaytomorrowContract();
      case 'flexshopper':
        return this.renderFlexshopperContract();
      case 'acima':
      case 'tempoe':
        return this.renderTempoeContract();
      default:
        return null;
    }
  }

  render() {
    const { selectedOffer } = this.props
    return this.renderSwitch(selectedOffer.provider);
  }
}

const mapStateToProps = createStructuredSelector({
  selectedOffer: selectedOfferSelector()
});

ContractStep = connect(mapStateToProps, null)(ContractStep);


export default ContractStep;

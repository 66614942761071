import React, { Component } from 'react';
import ReactCodeInput from 'react-verification-code-input';
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import { ValidationIcon } from "components/Controls";
import ValidationError from "../../Fields/ValidationError";

import "./styles.scss";

class VerificationCode extends Component {
  constructor(props) {
    super(props);
    this.codeInput = React.createRef();
  }

  resendCode = () => {
    const { onChange, onResend } = this.props;
    this.codeInput.current.setState({ values: Array(this.codeInput.current.props.fields).fill('') });
    onResend();
    onChange('');
  }

  render() {
    const { trigger, text, resend, onChange, label, meta: {valid, error, touched}, ...props } = this.props
    return (
      <div className={classNames('animated verification_code_section',{
        bounceInRight: trigger,
        shown: trigger
      })}>
        <p>{text}</p>
      
        <div className="verification_code_component">
          {label && (<label className="flex flex__align--baseline font-uppercased text-field-label">{label}</label>)}
          <div className="pos-relative">
            <ReactCodeInput 
              fieldWidth={40}
              fieldHeight={40}
              className="verification_code"
              onChange={onChange}
              {...props}
              fields={6}
              ref={this.codeInput}
            />
            <ValidationIcon
              touched={touched}
              valid={props.value.length === 6}
            />   
            <ValidationError
              touched={touched}
              error={error}
            />
          </div>
     
        

          {resend && (<span 
            className="resend_code_button"
            onClick={this.resendCode}
          >RESEND CODE</span>)}
          </div>
        
      </div>
    );
  }
};

VerificationCode.propTypes = {
  trigger: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  onResend:  PropTypes.func,
  resend:  PropTypes.bool
  // onComplete: PropTypes.func
};

VerificationCode.defaultProps = {
  trigger: false,
  text: '',
  resend: true,
  onResend: () => {},
  label: 'VERIFICATION CODE'
  // onComplete: () => {}
};

export default VerificationCode;

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind"; 
import { providerLogos } from './constant';
import "./styles.scss";

class LogoView extends PureComponent {
  renderLogo = provider => { 
    if (provider && providerLogos[provider]) { 
      return <img src={providerLogos[provider].img} alt={`${provider} Logo`} />;
    }

    return null;
  };

  render() {
    const { provider } = this.props;
    return (
      <div
        className={classNames("logo-view-component", {
          "without-logo": provider === ""
        })}
      >
        {this.renderLogo(provider)}
      </div>
    );
  }
}

LogoView.propTypes = {
  provider: PropTypes.string.isRequired
};

LogoView.defaultProps = {
  provider: ""
};

export default LogoView;

import React from "react";
import PropTypes from "prop-types";
import Checkbox from "@material-ui/core/Checkbox";
import './styles.scss';

const CustomCheckbox = ({ value, onChange, customChange, ...props }) => {
  const combinedChange = (e) => {
    const value = e.target.checked;
    onChange(value);
    setTimeout(() => customChange(value), 0);
  };
  return (
    <div>
      <Checkbox 
        checked={value ? true : false}
        {...props}
        disableRipple={true}
        onChange={combinedChange}
      />
    </div>
  )
};

CustomCheckbox.propTypes = {
  customChange: PropTypes.func,
};


CustomCheckbox.defaultProps = {
  customChange: () => {}
};


export default CustomCheckbox;

import React from "react";

const SSN_ITIN_TOOLTIP_TEXT =
  "We use this information as a way to verify your identity.";
const SSN_ITIN_TOOLTIP_TITLE = "Why do we need this information?";

const INCOME_TOOLTIP_TEXT = (
  <div>
    
      Alimony, child support, or separate maintenance income need not be
      included unless relied upon for credit. You may include the monthly amount
      that you have available to spend from your assets.
      <br/>
      <span className="font-semibold"> Married WI Residents Only: </span> If you are applying for an
      individual account and your spouse also is a WI resident, combine your and
      your spouse’s financial information.
   
  </div>
);

export const STEP2__FEILDS = {
  income: {
    label: "Gross MONTHLY INCOME",
    tooltipType: "icon",
    placeholder: "",
    tooltipText: INCOME_TOOLTIP_TEXT,
    tooltipTitle: null
  },
  ssn: {
    label: "SSN / ITIN",
    tooltipType: "text",
    placeholder: "123-45-6789",
    tooltipText: SSN_ITIN_TOOLTIP_TEXT,
    tooltipTitle: SSN_ITIN_TOOLTIP_TITLE
  }
};

import { ofType, combineEpics } from 'redux-observable';
import { withLatestFrom, flatMap, catchError } from 'rxjs/operators';
import { of, from } from 'rxjs';

import { ApplicationRequestAPI } from 'api/application_request';
import { ApplicationAPI } from 'api/application';
import { UsersAPI } from 'api/users';

import * as ApplicationRequestActions from './actions';
import { ActionTypes } from './actionTypes';

import * as WidgetActions from 'containers/EmbeddableWidget/actions';


const init = (action$, state$) => action$.pipe(
  ofType(ActionTypes.init),
  withLatestFrom(state$),
  flatMap(([ action, state ]) => {
    if (state.widget.inIframe) {
      return [];
    } 
    return [
      ApplicationRequestActions.initUserData(action.payload)
    ]
  }),
);

const retrieveVisitorId = () => {
  return document.cookie.replace(/(?:(?:^|.*;\s*)__attentive_id\s*=\s*([^;]*).*$)|^.*$/, "$1");
}

const subscribe = (state, status = 'approved') => {
  if(!state.form.form.values.isAgreed) {
    return false;
  }
  if(process.env.REACT_APP_PAYPAIR_ENV !== 'production'){
    return false;
  }
  return UsersAPI.subscribe(state.widget.key, {
    "phone": state.leaseForm.applicationRequest.personalInfo.phone,
    "visitor_id": retrieveVisitorId(), 
    "status": status
  })
}

const initApplication = (action$, state$) => action$.pipe(
  ofType(ActionTypes.initApplication),
  withLatestFrom(state$),
  flatMap(([ action, state ]) => {
    return from(ApplicationRequestAPI.init(
      state.widget.key,
      {application: state.leaseForm.applicationRequest}
    ))
    .pipe(
      flatMap(data => {
        if (data.errors) {
          subscribe(state, "declined")
          return of(ApplicationRequestActions.initApplicationFailed())
          
        }
        subscribe(state, "approved")
        return [
          ApplicationRequestActions.initApplicationSuccess({data}),
          ApplicationRequestActions.processApplicationResponse(data)
        ]
      }),
      catchError(err => {
        return of(ApplicationRequestActions.initApplicationFailed()
      )}),
    )
  }),
);

const processApplicationResponse = (action$, state$) => action$.pipe(
  ofType(ActionTypes.processApplicationResponse),
  withLatestFrom(state$),
  flatMap(([ action, state ]) => {
    const data = action.payload

    if (!data.offers || data.offers.length === 0) {
      return of(ApplicationRequestActions.nextStep({step: 'nobodyApprovedStep'}))
    }

    const approved_not_finished = data.offers.filter(o => o.approve_status === 'approved' && o.is_finished === false);
    const need_additionals = data.offers.filter(o => o.approve_status === 'need_additionals');
    const finished = data.offers.find(o => o.approve_status === 'approved' && o.is_finished === true);

    if (finished) {
      return of(ApplicationRequestActions.nextStep({
        step: 'alreadyCompletedStep',
        data: { app: finished }
      }))
    }

    if (approved_not_finished.length === 0) { 
      if (need_additionals.length > 0) {
        return of(ApplicationRequestActions.nextStep({step:'additionalInfoStep'}))
      } 
  
      return of(ApplicationRequestActions.nextStep({step:'nobodyApprovedStep'}))
    }

    return of(ApplicationRequestActions.nextStep({step: 'form4'}))
  })
);

const continueInitApplication = (action$, state$) => action$.pipe(
  ofType(ActionTypes.continueInitApplication),
  withLatestFrom(state$),
  flatMap(([ action, state ]) => {
    return from(ApplicationRequestAPI.continue(
      state.widget.key,
      state.leaseForm.requestId,
      {application: state.leaseForm.applicationRequest}))
    .pipe(
      flatMap(data => {

        if (data.errors) {
          return of(ApplicationRequestActions.initApplicationFailed())
        }

        return [
          ApplicationRequestActions.continueInitApplicationSuccess({data}),
          ApplicationRequestActions.processApplicationResponse(data)
        ]

      }),
      catchError(err => {
        return of(ApplicationRequestActions.initApplicationFailed()
      )}),
    )
  }),
);


// const acceptOffer = (action$, state$) => action$.pipe(
//   ofType(ActionTypes.acceptOffer),
//   withLatestFrom(state$),
//   flatMap(([ action, state ]) => {
//     return [
//       ApplicationRequestActions.acceptOfferRequest(state.leaseForm.selectedOffer)
//     ]
//   }),
// )

// const acceptOfferRequest = (action$, state$) => action$.pipe(
//   ofType(ActionTypes.acceptOfferRequest),
//   withLatestFrom(state$),
//   flatMap(([ action, state ]) => {
//     return from(ApplicationAPI.accept_the_offer(
//       state.widget.key,
//       action.payload.app_id, 
//       { offer_id: action.payload.offer_id }
//     ))
//     .pipe(
//       flatMap(data => {
//         if (data.errors) {
//           return of(ApplicationRequestActions.acceptOfferFailed())
//         }

//         return of(ApplicationRequestActions.acceptOfferSuccess(data))
//       }),
//       catchError(err => {
//         return of(ApplicationRequestActions.acceptOfferFailed()
//       )}),
//     )
//   }),
// )

/////////////////////////////////////////////////////////////////
// set selected offer to reduser and call accept_the_offer for flexshopper
// should go to confirmationStep
const selectOffer = (action$, state$) => action$.pipe(
  ofType(ActionTypes.selectOffer),
  withLatestFrom(state$),
  flatMap(([ action, state ]) => {
    const selectedOffer = state.leaseForm.selectedOffer
    return from(ApplicationAPI.accept_the_offer(
      state.widget.key,
      selectedOffer.app_id, 
      action.payload.body || {}
    ))
    .pipe(
      flatMap(data => {
        if (data.errors) {
          return of(ApplicationRequestActions.selectOfferFailed())
        }
        return of( ApplicationRequestActions.selectOfferSuccess(data));
      }),
      catchError(err => {
        return of(ApplicationRequestActions.selectOfferFailed())
      })
    )
  }),
);

const selectOfferSuccess = (action$, state$) => action$.pipe(
  ofType(ActionTypes.selectOfferSuccess),
  withLatestFrom(state$),
  flatMap(([ action, state ]) => {
    const selectedOffer = state.leaseForm.selectedOffer
    let nextStep = 'confirmationStep';
    if(selectedOffer.provider === 'tempoe'){
      nextStep = 'contractStep';
    }
    if(selectedOffer.provider === 'flexshopper') {
      if(selectedOffer.verification_is_required){
        nextStep = 'verificationQuestionsStep';
      } else {
        nextStep = 'contractStep';
      }
    }
    return of(ApplicationRequestActions.nextStep({step: nextStep}));
  })
);


// used on confirmation step 
// does nothing for paytomorrow
// call accept_the_offer for flexshopper
// go to contract step for paytomorrow 
const confirmOffer = (action$, state$) => action$.pipe(
  ofType(ActionTypes.confirmOffer),
  withLatestFrom(state$),
  flatMap(([ action, state ]) => {
    const selectedOffer = state.leaseForm.selectedOffer
    if(selectOffer.provider === 'paytomorrow') {
      return of(ApplicationRequestActions.confirmOfferSuccess({}))
    }
    return from(ApplicationAPI.accept_the_offer(
      state.widget.key,
      selectedOffer.app_id, 
      { offer_id: selectedOffer.offer_id }
    ))
    .pipe(
      flatMap(data => {
        if (data.errors) {
          return of(ApplicationRequestActions.confirmOfferFailed())
        }

        return of(ApplicationRequestActions.confirmOfferSuccess(data))
      }),
      catchError(err => {
        return of(ApplicationRequestActions.confirmOfferFailed()
      )}),
    )
  }),
);



///////////////////////////////////////

const cancelApplication = (action$, state$) => action$.pipe(
  ofType(ActionTypes.cancelApplication),
  withLatestFrom(state$),
  flatMap(([ action, state ]) => {
    return from(ApplicationAPI.cancel(
      state.widget.key,
      state.leaseForm.selectedOffer.app_id,
      {}
    ))
    .pipe(
      flatMap(data => {
        return [];
      }),
      catchError(err => {
        return [];
      })
    )
  })
)

const finishApplication = (action$, state$) => action$.pipe(
  ofType(ActionTypes.finishApplication),
  withLatestFrom(state$),
  flatMap(([ action, state ]) => {
    return from(ApplicationAPI.finish(
      state.widget.key,
      state.leaseForm.selectedOffer.app_id
    ))
    .pipe(
      flatMap(data => {
        if (data.errors) {
          return of(ApplicationRequestActions.finishApplicationFailed())
        }

        let actions = [
          ApplicationRequestActions.finishApplicationSuccess(),
          ApplicationRequestActions.nextStep({step: 'successStep'}),
          WidgetActions.finishFlow()
        ];

        return actions;
      }),
      catchError(err => {
        return of(ApplicationRequestActions.finishApplicationFailed())
      })
    )
  })
)

const processAuthUserResponse = (action$, state$) => action$.pipe(
  ofType(ActionTypes.processAuthUserResponse),
  withLatestFrom(state$),
  flatMap(([ action, state ]) => {
    const data = action.payload.response
    if (!data.request) {
      return [ApplicationRequestActions.nextStep({step: 'form2'})]
    }

    if (data.can_be_updated) {
      return of(
        ApplicationRequestActions.updateApplication({
          request_id: data.request.id
        })
      )
    }

    if (data.already_completed) {
      if( data.already_completed.provider === 'paytomorrow') {
        return of(ApplicationRequestActions.nextStep({
          step: 'alreadyCompletedStep',
          data: { app: data.already_completed }
        }))
      } else {
        //TODO: !!!!!!!!!!
        return of(
          ApplicationRequestActions.updateCompletedApplication({
            request_id: data.request.id
          })
        )
      }
    }

    if (data.all_declined) {
      return of(ApplicationRequestActions.nextStep({step: 'nobodyApprovedStep'}))
    }

    // init new request
    return of(ApplicationRequestActions.nextStep({step: 'form2'}))
  })
)


const updateApplication = (action$, state$) => action$.pipe(
  ofType(ActionTypes.updateApplication),
  withLatestFrom(state$),
  flatMap(([ action, state ]) => {
    return from(ApplicationRequestAPI.update(
      state.widget.key,
      action.payload.request_id,
      {application: state.leaseForm.applicationRequest}
    ))
    .pipe(
      flatMap(data => {
        return [
          ApplicationRequestActions.initApplicationSuccess({data}),
          ApplicationRequestActions.processApplicationResponse(data)
        ]
      }),
      catchError(err => {
        return of(ApplicationRequestActions.initApplicationFailed()
      )}),
    )
  }),
);

const updateCompletedApplication = (action$, state$) => action$.pipe(
  ofType(ActionTypes.updateCompletedApplication),
  withLatestFrom(state$),
  flatMap(([ action, state ]) => {
    return from(ApplicationRequestAPI.updateCompletedApplication(
      state.widget.key,
      action.payload.request_id,
      {application: state.leaseForm.applicationRequest}
    ))
    .pipe(
      flatMap(data => {
        if (data.errors) {
          return of(ApplicationRequestActions.initApplicationFailed())
        }
        return [
          ApplicationRequestActions.initApplicationSuccess({data}),
          ApplicationRequestActions.processApplicationResponse(data)
        ]
      }),
      catchError(err => {
        return of(ApplicationRequestActions.initApplicationFailed()
      )}),
    )
  }),
);


// const processUpdateCompletedApplicationResponse = (action$, state$) => action$.pipe(
//   ofType(ActionTypes.processUpdateCompletedApplicationResponse),
//   withLatestFrom(state$),
//   flatMap(([ action, state ]) => {
//     const data = action.payload

//     if (data.offers.length === 0) {
//       return of(ApplicationRequestActions.nextStep({step: 'nobodyApprovedStep'}))
//     }

//     // const approved_not_finished = data.offers.filter(o => o.approve_status === 'approved' && o.is_finished === false);
//     // const need_additionals = data.offers.filter(o => o.approve_status === 'need_additionals');
//     const finished = data.offers.find(o => o.is_finished === true);

//     // if (finished) {
//     //   return of(ApplicationRequestActions.nextStep({
//     //     step: 'alreadyCompletedStep',
//     //     data: { app: finished }
//     //   }))
//     // }

//     // if (approved_not_finished.length > 0) { 
//     //   return of(ApplicationRequestActions.nextStep({step: 'form4'}))
//     // }
    
//     // if (need_additionals.length > 0) {
//     //   return of(ApplicationRequestActions.nextStep({step:'additionalInfoStep'}))
//     // } 

//     return of(ApplicationRequestActions.nextStep({step:'nobodyApprovedStep'}))
//   })
// );


export default combineEpics(
  init,
  initApplication,
  selectOffer,
  selectOfferSuccess,
  confirmOffer,
  cancelApplication,
  finishApplication,
  continueInitApplication,
  updateApplication,
  processApplicationResponse,
  processAuthUserResponse,
  updateCompletedApplication
)
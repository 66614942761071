import { capitalizeFirstletter, addThousandsSeparator } from "utils/helpers";

// selectedOffer = {
  // provider: ''
  // payment_schedule: ''
  // initial_payment: ''
  // first_period: '',
  // first_period_rate: '',
  // last_period: '',
  // last_period_rate: ''
  // payment_period_rate: ''
// }
export const buildOfferData = (selectedOffer, preview = false) => {
  if (selectedOffer.provider === 'paytomorrow') {
    return buildPaytomorrowOfferData(selectedOffer, preview);
  } else if (selectedOffer.provider === 'flexshopper') {
    return buildFlexshopperOfferData(selectedOffer, preview);
  } else if (selectedOffer.provider === 'tempoe') {
    return buildTempoeOfferData(selectedOffer, preview);
  } else if (selectedOffer.provider === 'genesis') {
    return buildGenesisOfferData(selectedOffer, preview);
  } else if (selectedOffer.provider === 'okinus') {
    return buildOkinusOfferData(selectedOffer, preview);
  } else {
    return [[{
      title: 'PAYMENT PERIOD',
      value: `${selectedOffer.payment_period} Months`
    },{
      title: 'PAYMENT SCHEDULE',
      value:  capitalizeFirstletter(selectedOffer.payment_schedule)
    }],[{
      title: 'DUE TODAY',
      value: `$${addThousandsSeparator(selectedOffer.initial_payment)}`
    },{
      title: 'DUE WEEKLY',
      value: `$${addThousandsSeparator(selectedOffer.initial_payment)}`
    }]]
  }
}

export const buildPaytomorrowOfferData = (selectedOffer, preview = false) => {
  let properties = [];
  if (selectedOffer.payment_period && preview){
    properties.push([{
      title: 'PAYMENT PERIOD',
      value: `${selectedOffer.payment_period} Months`
    }])
  }
  properties.push([{
    title: 'PAYMENT SCHEDULE',
    value:  capitalizeFirstletter(selectedOffer.payment_schedule)
  }])
  if (selectedOffer.first_period && selectedOffer.first_period_rate && 
    selectedOffer.last_period && selectedOffer.last_period_rate) {
      properties.push([{
        title: `FIRST ${selectedOffer.first_period} MONTHS`,
        value: `$${addThousandsSeparator(selectedOffer.first_period_rate)}`,
        note: '/ month'
      }])
      properties.push([{
        title: `LAST ${selectedOffer.last_period} MONTHS`,
        value: `$${addThousandsSeparator(selectedOffer.last_period_rate)}`,
        note: '/ month'
      }])
  } else {
    properties.push([{
      title: `PAYMENT`,
      value: `$${addThousandsSeparator(selectedOffer.payment_period_rate)}`,
      note: '/ month'
    }])
  }
  return properties;
};


export const buildFlexshopperOfferData = (selectedOffer, preview = false) => {
  return [[{
    title: 'PAYMENT PERIOD',
    value: `${selectedOffer.payment_period} Weeks`
  }],[{
    title: 'PAYMENT SCHEDULE', 
    value:  capitalizeFirstletter(selectedOffer.payment_schedule)
  }],[{
    title: 'DUE WEEKLY',
    titleTooltip: "With your Lease, you are agreeing to make weekly payments for at least the initial lease term, which may vary by state but ranges from 7 days to 29 days. If you rent the goods for at least the initial term and you give us proper notice, make all of your required payments and return th eitem in good condition, the Lease ends and you have no further obligation to us. The Lease continues until you end it early or make all required payments for 52 weeks. You acquire the goods if you make all required payments for 52 weeks or exercise your Early Purchase Option. Simply refer to the table in your Lease to look up the Early Purchase Option amount.",
    value: `$${selectedOffer.payment_period_rate}`,
    note: '/ week'
  }]]
};


export const buildTempoeOfferData = (selectedOffer, preview = false) => {
  const per = selectedOffer.payment_schedule === 'monthly' ? 'month' : (selectedOffer.payment_schedule === 'weekly' ? 'week' : 'bi-week')
  return [[{
    title: 'PAYMENT PERIOD',
    value: `${selectedOffer.payment_period_min} Months Min / <br/> ${selectedOffer.payment_period_max} Months Max`,
    tooltip: 'See Lease Disclosures'
  }],[{
    title: 'PAYMENT SCHEDULE',
    value:  capitalizeFirstletter(selectedOffer.payment_schedule)
  }],[{
    title: `DUE ${selectedOffer.payment_schedule}`,
    value: `$${selectedOffer.payment_period_rate}`,
    note: `/ ${per}`
  }]]
}

export const buildOkinusOfferData = (selectedOffer, preview = false) => {
  return [[{
    title: 'PAYMENT PERIOD',
    value: `${selectedOffer.payment_period} Months`,
  }],[{
    title: 'PAYMENT SCHEDULE',
    value:  capitalizeFirstletter(selectedOffer.payment_schedule)
  }],[{
    title: `DUE ${selectedOffer.payment_schedule}`,
    value: `$${selectedOffer.payment_period_rate}`,
    note: `/ month`
  }]]
}

export const buildGenesisOfferData = (selectedOffer, preview = false) => {
  return [[{
    title: 'PAYMENT SCHEDULE',
    value:  capitalizeFirstletter(selectedOffer.payment_schedule)
  }],[{
    title: `DUE ${selectedOffer.payment_schedule}`,
    value: `$${selectedOffer.payment_period_rate}`,
    note: `/ month`
  }]]
}


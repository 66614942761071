import React, { Component } from "react";
import { connect } from "react-redux";
import Breadcrumb from "components/Breadcrumb";
import { breadcrumbMap, FORMS } from "./constants";
import "./styles.scss";
import { createStructuredSelector } from 'reselect';
import { activeStepSelector } from './selectors';
import { init } from "./actions";
import { userDefaultData } from 'utils/stubData';

class MultistepForm extends Component {
  componentDidMount = () => {
    this.props.init(userDefaultData);
  }

  render() { 
    const { activeStep } = this.props;
    const Form = FORMS[activeStep].form;
    const activeBreadcrumb = FORMS[activeStep].breadcrumb;
    return (
      <div className="multistep-form" >
        {activeStep !== 'form1' && <Breadcrumb data={breadcrumbMap} activeBreadcrumb={activeBreadcrumb} />}
        <Form/>
      </div>
    );
  }
};

const mapStateToProps = createStructuredSelector({
  activeStep: activeStepSelector()
});
  
const mapDistpatchToProps = dispatch => {
  return {
    init: values => dispatch(init(values))
  };
};

export default connect(mapStateToProps, mapDistpatchToProps)(MultistepForm);


import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import { IconChevron } from "components/Svgs";
import "./styles.scss";

const Breadcrumb = ({ data, activeBreadcrumb }) => {
  let passed = true;
  return (
    <div className="breadcrumb-wrapper flex flex__align--center  flex__justify--center">
      { data.map(item => {
          if (item.label === activeBreadcrumb) {
            passed = false;
          }
          return (

          <div
            key={item.key}
            className={classNames("breadcrumb-item", {
              passed: passed,
              active: item.label === activeBreadcrumb
            })}
          >
            <IconChevron className="svg-icon" />
            {item.label}
          </div>
        )})}
    </div>
  );
};

Breadcrumb.propTypes ={
  data: PropTypes.array,
  activeBreadcrumb: PropTypes.string
}

Breadcrumb.defaultPropTypes ={
  data: [],
}

export default Breadcrumb;

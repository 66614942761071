import React, { Component } from "react";
import PropTypes from "prop-types";
import AuthenticationRequiredOffer from "./AuthenticationRequiredOffer"; 
import Offer from "./Offer"; 

class FlexshopperOffer extends Component {
  render() {
    const { offer, available } = this.props;

    if (offer.authentication_is_required) {
      return <AuthenticationRequiredOffer  offer={offer} />;
    } else {
      return <Offer  offer={offer} available={available}/>;
    }
  }
}

FlexshopperOffer.propTypes = {
  offer: PropTypes.object.isRequired,
  available:  PropTypes.bool
};

FlexshopperOffer.defaultProps = {
  available: true
};

export default FlexshopperOffer;


import { call } from 'api/base';

export const ApplicationAPI = {  
  accept_the_offer(token, app_id, data) {
    return call(`applications/${app_id}/accept_the_offer`, 'POST', token, data)
  },

  finish(token, app_id) {
    return call(`applications/${app_id}/finish`, 'POST', token);
  },

  cancel(token, app_id, data) {
    return call(`applications/${app_id}/cancel`, 'POST', token, data);
  },

  settle(token, app_id, data) {
    return call(`applications/${app_id}/settle`, 'POST', token, data);
  },

  refund(token, app_id, data) {
    return call(`applications/${app_id}/refund`, 'POST', token, data);
  },

  sendPasscode(token, app_id) {
    return call(`applications/${app_id}/provider_passcode`, 'POST', token);
  },

  passcodeLogin(token, app_id, data) {
    return call(`applications/${app_id}/provider_login`, 'POST', token, data);
  },

  update(token, app_id, data) {
    return call(`applications/${app_id}`, 'PATCH', token, data);
  }
}

import { call } from 'api/base';

export const ApplicationRequestAPI = {  
  preinit(token) {
    return call(`application_requests/preinit`, 'POST', token, {});
  },

  init(token, data) {
    return call(`application_requests`, 'POST', token, data);
  },

  continue(token, request_id, data) {
    return call(`application_requests/${request_id}/continue`, 'POST', token, data);
  },

  update(token, request_id, data) {
    return call(`application_requests/${request_id}`, 'PATCH', token, data);
  },

  updateCompletedApplication(token, request_id, data) {
    return call(`application_requests/${request_id}/balance`, 'POST', token, data);
  }
}